import { Commit } from "vuex";

const HomeMenuStore = {
  state: {
    //используется в LeftSideMenuComp
    visibleSubMenu: null,
    //левое меню, используется в LeftSideMenuComp
    homePageMenu: [
      {
        title: "ОБ УЧРЕЖДЕНИИ",
        alias: "about",
        url: "/about",
      },
      {
        title: "СТРУКТУРА",
        alias: "structure",
        url: "/structure",
      },
      {
        title: "УСЛУГИ",
        alias: "services",
        url: "/services",
      },
      {
        title: "ЛАБОРАТОРНЫЙ ЦЕНТР",
        alias: "lab",
        url: "/lab",
      },
      {
        title: "ЭКСПЕРТИЗА",
        alias: "expert",
        url: "/price",
      },
      {
        title: "НОВОСТИ",
        alias: "news",
        url: "/news",
      },
      {
        title: "КОНТАКТЫ",
        alias: "contacts",
        url: "/contacts",
      },
    ],
    //используется в RighttSideMenuComp
    mainLink: {
      title: `ГИГИЕНИЧЕСКОЕ ОБУЧЕНИЕ в&nbsp;Запорожской области`,
      alias: "Education",
      url: "/education",
    },
    //используется в RighttSideMenuComp
    rightSideLinks: [
      {
        id: 1,
        title: "Перечень платных услуг для Физ. лиц",
        link: "/price",
      },
      {
        id: 2,
        title: "Перечень платных услуг для Юр. лиц",
        link: "/price",
      },
      {
        id: 3,
        title: "Личные медицинские книжки",
        link: "/medBooks",
      },
    ],
  },
  rootGetters: {},
  mutations: {
    openSubMenu(state: any, alias: string) {
      state.visibleSubMenu = alias;
    },
  },
  //используется в LeftSideMenuComp
  actions: {
    openMenu({ commit }: { commit: Commit }, alias: string) {
      commit("openSubMenu", alias);
    },
  },
};

export default HomeMenuStore;
