import { v4 as uuidv4 } from 'uuid';

const NavigationStore = {
  state: () => ({
    //меню зеленая полоса расширенное, используется в NavigationComp
    menuItems: [
      {
        id: 1,
        title: 'О ЦЕНТРЕ',
        alias: 'about',
        url: '/about',
        hasSubMenu: true,
        subMenu: [
          {
            id: uuidv4(),
            title: 'Руководство',
            alias: 'management',
            url: '/management',
          },
          {
            id: uuidv4(),
            title: 'Структура',
            alias: 'structure',
            url: '/structure',
          },
          {
            id: uuidv4(),
            title: 'История',
            alias: 'history',
            url: '/history',
          },
          {
            id: uuidv4(),
            title: 'Документы',
            alias: 'documents',
            url: '/documents',
            hasSubMenu: false,
          },
          {
            id: uuidv4(),
            title: 'Филиалы',
            alias: 'filials',
            url: '/filials',
            hasSubMenu: true,
            subSubMenu: [
              {
                id: uuidv4(),
                title: 'Пологовский филиал ФБУЗ «ЦГиЭ в ЗО»',
                alias: 'tokmak',
                url: '/filials/tokmak',
              },
              {
                id: uuidv4(),
                title: 'Бердянский филиал ФБУЗ «ЦГиЭ в ЗО»',
                alias: 'berdyansk',
                url: '/filials/berdyansk',
              },
            ],
          },
          {
            id: uuidv4(),
            title: 'Вакансии',
            alias: 'jobs',
            url: '/jobs',
          },
        ],
      },
      {
        id: 2,
        title: 'УСЛУГИ',
        alias: 'services',
        url: '/services',
        hasSubMenu: true,
        subMenu: [
          {
            id: uuidv4(),
            title: 'Прейскурант',
            alias: 'priceList',
            url: '/price',
          },
          {
            id: uuidv4(),
            title: 'Услуги для населения',
            alias: 'servicesForPublic',
            url: '/services-for-people',
          },
          {
            id: uuidv4(),
            title: 'Услуги для ЮЛ и ИП',
            alias: 'servicesForIP',
            url: '/servicesForIP',
          },
          {
            id: uuidv4(),
            title: 'Личные медицинские книжки. Профессиональная гигиеническая подготовка',
            alias: 'medBooks',
            url: '/medBooks',
          },
        ],
      },
      {
        id: 3,
        title: 'СТРУКТУРА',
        alias: 'structure',
        url: '/structure',
        subMenu: null,
      },
      {
        id: 4,
        title: 'ЛАБОРАТОРНЫЙ ЦЕНТР',
        alias: 'lab',
        url: '/lab',
        hasSubMenu: true,
        subMenu: [
          {
            id: uuidv4(),
            title: 'Структура ИЛЦ',
            alias: 'Structure-ILC',
            url: '/structure-ilc',
          },
          {
            id: uuidv4(),
            title: 'Бактериологическая',
            alias: 'bacteriological',
            url: '/bacteriological',
          },
          {
            id: uuidv4(),
            title: 'Вирусологическая',
            alias: 'virological',
            url: '/virological',
          },
          {
            id: uuidv4(),
            title: 'Особо опасные инфекции',
            alias: 'particularly-dangerous-infections',
            url: '/particularly-dangerous-infections',
          },
          {
            id: uuidv4(),
            title: 'Паразитологическая',
            alias: 'parasitological',
            url: '/parasitological',
          },
          {
            id: uuidv4(),
            title: 'Химическая',
            alias: 'chemical',
            url: '/chemical',
          },
          {
            id: uuidv4(),
            title: 'Физические факторы',
            alias: 'physical-factors',
            url: '/physical-factors',
          },
          {
            id: uuidv4(),
            title: 'Радиологическая',
            alias: 'radiological',
            url: '/radiological',
          },
          {
            id: uuidv4(),
            title: 'Токсикологическая',
            alias: 'toxicological',
            url: '/toxicological',
          },
          {
            id: uuidv4(),
            title: 'Аттестат аккредитации, лицензии',
            alias: 'certificate',
            url: '/certificate',
          },
          {
            id: uuidv4(),
            title: 'Заключить договор на проведение лабораторных исследований',
            alias: 'services',
            url: '/services',
          },
          {
            id: uuidv4(),
            title: 'Филиалы',
            alias: 'filials',
            url: '/filials',
            hasSubMenu: true,
            subSubMenu: [
              {
                id: uuidv4(),
                title: 'Пологовский филиал ФБУЗ «ЦГиЭ в ЗО»',
                alias: 'tokmak',
                url: '/filials/tokmak',
              },
              {
                id: uuidv4(),
                title: 'Бердянский филиал ФБУЗ «ЦГиЭ в ЗО»',
                alias: 'berdyansk',
                url: '/filials/berdyansk',
              },
            ],
          },
        ],
      },
      {
        id: 5,
        title: 'ЭКСПЕРТИЗА',
        alias: 'expert',
        url: '/price',
        hasSubMenu: true,
        subMenu: [
          {
            id: uuidv4(),
            title: 'Экспертиза документации',
            alias: 'documentation-review',
            url: '/documentation-review',
          },
          {
            id: uuidv4(),
            title: 'Экспертиза продукции',
            alias: 'product-expertise',
            url: '/product-expertise',
          },
          {
            id: uuidv4(),
            title: 'Экспертиза видов деятельности (работ, услуг)',
            alias: 'examination-of-types-of-activities',
            url: '/examination-of-types-of-activities',
          },
          {
            id: uuidv4(),
            title: 'Стоимость работ',
            alias: 'price',
            url: '/price',
          },
        ],
      },
      {
        id: 6,
        title: 'НОВОСТИ',
        alias: 'news',
        url: '/news',
        subMenu: null,
      },
      {
        id: 7,
        title: 'КОНТАКТЫ',
        alias: 'contacts',
        url: '/contacts',
        hasSubMenu: true,
        subMenu: [
          {
            id: uuidv4(),
            title: 'Телефонный справочник',
            alias: 'phoneBook',
            url: '/phoneBook',
          },
          {
            id: uuidv4(),
            title: 'Реквизиты',
            alias: 'requisites',
            url: '/requisites',
          },
          {
            id: uuidv4(),
            title: 'Обращения граждан',
            alias: 'appeals-of-citizens',
            url: '/appeals-of-citizens',
          },
          {
            id: uuidv4(),
            title: 'Онлайн оплата',
            alias: 'online-payment',
            url: '/online-payment',
          },
        ],
      },
    ],
  }),
  rootGetters: {},
  mutations: {},
  actions: {},
};

export default NavigationStore;
