import { v4 as uuidv4 } from 'uuid';

const PriceTable = {
  state: () => ({
    price: [
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 1. УСЛУГИ ПО ОБУЧЕНИЮ ГРАЖДАН, прочие услуги.',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '1.1',
            title:
              'Гигиеническое воспитание населения, обучение граждан по вопросам обеспечения санэпидблагополучия населения (стоимость за 1 человека, время обучения 135 мин)',
            type: 'обучение',
            rawPrice: '330,00',
            ndsPrice: '396,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.2',
            title: 'Аттестация гигиенической подготовки 1 человека (время 45 мин)',
            type: 'аттестация',
            rawPrice: '160,00',
            ndsPrice: '192,00  ',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.3',
            title:
              'Оформление, выдача и учет личных медицинских книжек работникам отдельных профессий, предприятий, учреждений и организаций, деятельность которых связана с производством, хранением, транспортировкой и реализацией пищевых продуктов и питьевой воды, воспитанием и обучением детей, коммунальным и бытовым обслуживанием населения',
            type: '1 медкнижка',
            rawPrice: '190,00',
            ndsPrice: '228,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.4',
            title:
              'Гигиеническое воспитание населения, обучение граждан по вопросам обеспечения санэпидблагополучия населения для организованных групп от 20 человек (стоимость за 1 человека, время обучения 135 мин)',
            type: 'обучение',
            rawPrice: '250,00',
            ndsPrice: '300,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.5',
            title: 'Аттестация гигиенической подготовки 1 человека в составе организованной группы (время 45 мин)',
            type: 'аттестация',
            rawPrice: '130,00',
            ndsPrice: '156,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.6',
            title:
              'Дистанционное обучение граждан по вопросам обеспечения санэпидблагополучия населения аттестация (стоимость за 1 человека)',
            type: 'обучение, аттестация',
            rawPrice: '400,00',
            ndsPrice: '480,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.7',
            title:
              'Выездное обучение граждан по вопросам обеспечения санэпидблагополучия населения, аттестация для организованных групп от 30 человек (стоимость за 1 человека)',
            type: 'обучение, аттестация',
            rawPrice: '436,67',
            ndsPrice: '524,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.8',
            title: 'Гигиеническая оценка автотранспорта',
            type: 'гигиеническая оценка',
            rawPrice: '700,00',
            ndsPrice: '840,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.9',
            title: 'Оказание консультационных услуг по вопросам:',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.9.1',
            title: 'защиты прав потребителей',
            type: '1 устная консультация',
            rawPrice: '223,33',
            ndsPrice: '268,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.9.2',
            title: 'защиты прав потребителей',
            type: 'проект претензии',
            rawPrice: '503,33',
            ndsPrice: '604,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.9.3',
            title: 'защиты прав потребителей',
            type: 'проект искового заявления',
            rawPrice: '1 285,00',
            ndsPrice: '1 542,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.10',
            title: 'Разработка программы лабораторного контроля пищевой продукции в целях обоснования сроков годности',
            type: 'программа',
            rawPrice: '3 168,33',
            ndsPrice: '3 802,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.11',
            title: 'Гигиеническая оценка плана лабораторного контроля по программе производственного контроля',
            type: 'гигиеническая оценка плана',
            rawPrice: '6 602,50',
            ndsPrice: '7 923,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.12',
            title: 'Гигиеническая оценка примерного меню учебно-воспитательного объекта',
            type: 'гигиеническая оценка',
            rawPrice: '3 168,33  ',
            ndsPrice: '3 802,00  ',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.13',
            title:
              'Консультация специалиста по разработке программ производственного контроля ',
            type: '1 ППК',
            rawPrice: '2 200,00',
            ndsPrice: '2 640,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.14',
            title:
              'Разработка программы производственного контроля для 1 категории объектов: государственные, муниципальные образовательные учреждения; государственные, муниципальные учреждения здравоохранения ;  государственные,  муниципальные учреждения социальной защиты населения; государственные, муниципальные учреждения культуры.',
            type: '',
            rawPrice: '15 500,00',
            ndsPrice: '18 600,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.15',
            title:
              'Разработка программы производственного контроля  для 2 категории объектов: государственные и муниципальные ресурсоснабжающие предприятия;  предприятия микробизнеса и малого бизнеса, в том числе ИП, с штатной численностью сотрудников до 15, кроме объектов общественного питания, рекреации и отдыха, а также деятельности, связанной с потенциальной повышенной опасностью для среды обитания человека.',
            type: '',
            rawPrice: '20 416,67',
            ndsPrice: '24 500,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '1.16',
            title:
              'Разработка программы производственного контроля для 3 категории объектов: предприятия и организации государственной, муниципальной, частной формы собственности с штатной численностью сотрудников более 15, не вошедшие в категории 1 и 2. ',
            type: '',
            rawPrice: '24 500,00',
            ndsPrice: '29 400,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary:
          'Раздел 2. УСЛУГИ ПО ТРАНСПОРТИРОВКЕ СПЕЦИАЛИСТА И/ИЛИ ОБОРУДОВАНИЯ при отборе проб, проведении исследований (измерений), при проведении разовых дезинфекционных, дератизационных, дезинсекционных и иных видов работ, в т.ч. доставка проб в ИЛЦ средствами Учреждения (филиала) исходя из расстояния от ИЛЦ до пункта назначения:',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '2.1',
            title: 'до 10 км',
            type: 'выезд',
            rawPrice: '250,00',
            ndsPrice: '300,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '2.2',
            title: 'до 20 км',
            type: 'выезд',
            rawPrice: '450,00',
            ndsPrice: '540,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '2.3',
            title: 'до 30 км',
            type: 'выезд',
            rawPrice: '600,00',
            ndsPrice: '720,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '2.4',
            title: 'до 60 км',
            type: 'выезд',
            rawPrice: '1 248,33',
            ndsPrice: '1 498,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '2.5',
            title: 'до 90 км',
            type: 'выезд',
            rawPrice: '1 801,67',
            ndsPrice: '2 162,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '2.6',
            title: 'до 120 км',
            type: 'выезд',
            rawPrice: '2 401,67',
            ndsPrice: '2 882,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '2.7',
            title: 'до 150 км',
            type: 'выезд',
            rawPrice: '3 000,00',
            ndsPrice: '3 600,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '2.8',
            title: 'до 180 км',
            type: 'выезд',
            rawPrice: '3 600,00',
            ndsPrice: '4 320,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '2.9',
            title: 'до 210 км',
            type: 'выезд',
            rawPrice: '4 200,00',
            ndsPrice: '5 040,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 3. ДЕРАТИЗАЦИОННЫЕ, ДЕЗИНСЕКЦИОННЫЕ, ДЕЗИНФЕКЦИОННЫЕ УСЛУГИ',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '3.1',
            title: 'ДЕРАТИЗАЦИЯ РАЗОВАЯ (разнокатегорийных объектов и их территорий)',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.1.1',
            title: 'Помещений площадью до 100 кв. м',
            type: 'объект',
            rawPrice: '1 950,00',
            ndsPrice: '2 340,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.1.2',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '17,50',
            ndsPrice: '21,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.1.3',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '16,67',
            ndsPrice: '20,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.1.4',
            title: 'Помещений площадью свыше 1001 (кв. м)',
            type: 'кв.метр',
            rawPrice: '15,00',
            ndsPrice: '18,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.1.5',
            title: 'Дератизация территории разнокатегорийных объектов',
            type: 'га',
            rawPrice: '14 900,00',
            ndsPrice: '17 880,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.1.6',
            title: 'Дератизация территории животноводческих объектов, свалок, кладбищ, спецобъектов',
            type: 'га',
            rawPrice: '16 900,00',
            ndsPrice: '20 280,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2',
            title: 'ДЕРАТИЗАЦИЯ СИСТЕМАТИЧЕСКАЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.1',
            title: 'ДЕРАТИЗАЦИЯ пищевых и специальных объектов',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.1.1',
            title: 'Помещений площадью до 100 кв. м',
            type: 'объект',
            rawPrice: '1 690,00',
            ndsPrice: '2 028,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.1.2',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '16,67',
            ndsPrice: '20,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.1.3',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '15,00',
            ndsPrice: '18,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.1.4',
            title: 'Помещений площадью свыше 1001 (кв. м)',
            type: 'кв.метр',
            rawPrice: '14,17',
            ndsPrice: '17,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.2',
            title: 'ДЕРАТИЗАЦИЯ непищевых и оздоровительных объектов',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.2.1',
            title: 'Помещений площадью до 100 кв. м',
            type: 'объект',
            rawPrice: '1 590,00',
            ndsPrice: '1 908,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.2.2',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '15,00',
            ndsPrice: '18,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.2.3',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '14,17',
            ndsPrice: '17,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.2.4',
            title: 'Помещений площадью свыше 1001 (кв. м)',
            type: 'кв.метр',
            rawPrice: '13,33',
            ndsPrice: '16,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.3',
            title: 'ДЕРАТИЗАЦИЯ детских учебно-воспитательных объектов и объектов коммунально-бытового назначения',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.3.1',
            title: 'Помещений площадью до 100 кв. м',
            type: 'объект',
            rawPrice: '1 490,00',
            ndsPrice: '1 788,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.3.2',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '14,17',
            ndsPrice: '17,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.3.3',
            title: 'Помещений площадью 501-1000 (кв. м) ',
            type: 'кв.метр',
            rawPrice: '13,33',
            ndsPrice: '16,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.2.3.4',
            title: 'Помещений площадью свыше 1001 (кв. м) ',
            type: 'кв.метр',
            rawPrice: '12,50',
            ndsPrice: '15,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3',
            title: 'ДЕЗИНСЕКЦИЯ РАЗОВАЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.1',
            title: 'ДЕЗИНСЕКЦИЯ тараканов в помещениях',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.1.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 814,17',
            ndsPrice: '2 177,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.1.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '35,00',
            ndsPrice: '42,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.1.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '32,50',
            ndsPrice: '39,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.1.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '29,17',
            ndsPrice: '35,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.1.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '26,67',
            ndsPrice: '32,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.2',
            title: 'ДЕЗИНСЕКЦИЯ постельных клопов в помещениях',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.2.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '2 011,67',
            ndsPrice: '2 414,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.2.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '39,17',
            ndsPrice: '47,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.2.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '36,67',
            ndsPrice: '44,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.2.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '33,33',
            ndsPrice: '40,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.2.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '30,00',
            ndsPrice: '36,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.3',
            title: '',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.3.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 954,17',
            ndsPrice: '2 345,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.3.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '37,50',
            ndsPrice: '45,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.3.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '34,17',
            ndsPrice: '41,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.3.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '31,67',
            ndsPrice: '38,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.3.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '28,33',
            ndsPrice: '34,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.4',
            title: 'ДЕЗИНСЕКЦИЯ муравьёв, мокрицы, чешуйницы, мух, моли, мошкары и др.',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.4.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 700,00',
            ndsPrice: '2 040,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.4.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '33,33',
            ndsPrice: '40,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.4.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '30,00',
            ndsPrice: '36,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.4.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '27,50',
            ndsPrice: '33,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.4.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '24,17',
            ndsPrice: '29,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.5',
            title: 'ДЕЗИНСЕКЦИЯ комаров и личинок комаров в помещениях, в том числе подвальных',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.5.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 400,00',
            ndsPrice: '1 680,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.5.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '27,50',
            ndsPrice: '33,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.5.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '24,17',
            ndsPrice: '29,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.5.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '21,67',
            ndsPrice: '26,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.5.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '18,33',
            ndsPrice: '22,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.6',
            title: 'ДЕЗИНСЕКЦИЯ личинок комаров в водоёмах',
            type: 'га',
            rawPrice: 'ДОГОВОРНАЯ',
            ndsPrice: 'ДОГОВОРНАЯ',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.7',
            title: 'ДЕЗИНСЕКЦИЯ индивидуальных шкафчиков',
            type: 'шт.',
            rawPrice: '300,00',
            ndsPrice: '360,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.8',
            title: 'ДЕЗИНСЕКЦИЯ клещей в помещениях',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.8.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 870,00',
            ndsPrice: '2 244,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.8.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '36,67',
            ndsPrice: '44,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.8.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '33,33',
            ndsPrice: '40,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.8.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '40,00',
            ndsPrice: '48,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.8.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '27,50',
            ndsPrice: '33,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9',
            title: 'АКАРИЦИДНАЯ ОБРАБОТКА территорий',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.1',
            title: 'Для физических и юридических лиц',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.1.1',
            title: 'Площадью до 100 кв.м',
            type: 'объект',
            rawPrice: '990,00',
            ndsPrice: '1 188,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.1.2',
            title: 'Площадью 101-500 (кв.м)',
            type: 'кв.метр',
            rawPrice: '9,17',
            ndsPrice: '11,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.1.3',
            title: 'Площадью 501-1000 (кв.м)',
            type: 'кв.метр',
            rawPrice: '8,33',
            ndsPrice: '10,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.1.4',
            title: 'Площадью 1001-5000 (кв.м)',
            type: 'кв.метр',
            rawPrice: '4,17',
            ndsPrice: '5,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.1.5',
            title: 'Площадью от 5001 и более (кв. м)',
            type: 'кв.метр',
            rawPrice: '3,33',
            ndsPrice: '4,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.2',
            title:
              'Для учебно-воспитательных и оздоровительных учреждений, объектов коммунально-бытового назначения, стационаров, лабораторно-поликлинической сети, ЛПУ, муниципальных образований',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.2.1',
            title: 'Площадью до 100 кв.м',
            type: 'объект',
            rawPrice: '1 600,00',
            ndsPrice: '1 920,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.2.2',
            title: 'Площадью 101-500 (кв.м)',
            type: 'кв.метр',
            rawPrice: '32,50',
            ndsPrice: '39,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.2.3',
            title: 'Площадью 501-1000 (кв.м)',
            type: 'кв.метр',
            rawPrice: '29,17',
            ndsPrice: '35,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.2.4',
            title: 'Площадью 1001-5000 (кв.м)',
            type: 'кв.метр',
            rawPrice: '26,67',
            ndsPrice: '32,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.9.2.5',
            title: 'Площадью от 5001 и более (кв. м)',
            type: 'кв.метр',
            rawPrice: '23,33',
            ndsPrice: '28,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.10',
            title: 'ДЕЗИНСЕКЦИЯ тараканов в помещениях методом холодного тумана',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.10.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '2 133,33',
            ndsPrice: '2 560,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.10.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '42,5',
            ndsPrice: '51,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.10.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '40,00',
            ndsPrice: '48,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.10.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '36,67',
            ndsPrice: '44,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.10.5',
            title: 'Помещений площадью свыше 1001 (кв. м)',
            type: 'кв.метр',
            rawPrice: '34,17',
            ndsPrice: '41,00',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.11',
            title: 'ДЕЗИНСЕКЦИЯ постельных клопов в помещениях  методом холодного тумана',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.11.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '2 357,5',
            ndsPrice: '2 829,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.11.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '46,67',
            ndsPrice: '56,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.11.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '43,33',
            ndsPrice: '52,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.11.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '39,17',
            ndsPrice: '47,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.11.5',
            title: 'Помещений площадью свыше 1001 (кв. м)',
            type: 'кв.метр',
            rawPrice: '35,83',
            ndsPrice: '43,00',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.12',
            title: 'ДЕЗИНСЕКЦИЯ блох в помещениях методом холодного тумана',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.12.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '2 291,67',
            ndsPrice: '2 750,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.12.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '45,83',
            ndsPrice: '55,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.12.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '42,50',
            ndsPrice: '51,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.12.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '39,17',
            ndsPrice: '47,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.12.5',
            title: 'Помещений площадью свыше 1001 (кв. м)',
            type: 'кв.метр',
            rawPrice: '35,83',
            ndsPrice: '43,00',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.13',
            title: 'ДЕЗИНСЕКЦИЯ муравьев, мокрицы, чешуйницы, мух, моли, мошкары и др. методом холодного тумана',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.13.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '2 190,00',
            ndsPrice: '2 628,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.13.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '43,33',
            ndsPrice: '52,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.13.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '40,00',
            ndsPrice: '48,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.13.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '37,50',
            ndsPrice: '45,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.13.5',
            title: 'Помещений площадью свыше 1001 (кв. м)',
            type: 'кв.метр',
            rawPrice: '34,17',
            ndsPrice: '41,00',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.14',
            title:
              'ДЕЗИНСЕКЦИЯ комаров и личинок комаров в помещениях, в том числе подвальных методом холодного тумана',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.14.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 787,5',
            ndsPrice: '2 145,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.14.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '35,83',
            ndsPrice: '43,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.14.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '32,50',
            ndsPrice: '39,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.14.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '30,00',
            ndsPrice: '36,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.14.5',
            title: 'Помещений площадью свыше 1001 (кв. м)',
            type: 'кв.метр',
            rawPrice: '26,67',
            ndsPrice: '32,00',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '3.3.15',
            title: 'ДЕЗИНСЕКЦИЯ личинок комаров в водоёмах методом холодного тумана',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '3.4',
            title: 'ДЕЗИНСЕКЦИЯ СИСТЕМАТИЧЕСКАЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.1',
            title:
              'ДЕЗИНСЕКЦИЯ тараканов в помещениях учебно-воспитательных и оздоровительных учреждений, объектов коммунально-бытового назначения, стационаров, лабораторно-поликлинической сети, ЛПУ, муниципальных образований',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.1.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 600,00',
            ndsPrice: '1 920,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.1.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '32,50',
            ndsPrice: '39,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.1.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '29,17',
            ndsPrice: '35,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.1.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '26,67',
            ndsPrice: '32,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.1.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '23,33',
            ndsPrice: '28,00',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.2',
            title: 'ДЕЗИНСЕКЦИЯ тараканов в помещениях пищевых и непищевых объектов',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.2.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 700,00',
            ndsPrice: '2 040,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.2.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '33,33',
            ndsPrice: '40,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.2.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '30,00',
            ndsPrice: '36,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.2.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '27,50',
            ndsPrice: '33,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.2.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '24,17',
            ndsPrice: '29,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.3',
            title: 'ДЕЗИНСЕКЦИЯ блох в помещениях',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.3.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 954,17',
            ndsPrice: '2 345,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.3.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '37,50',
            ndsPrice: '45,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.3.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '34,17',
            ndsPrice: '41,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.3.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '31,67',
            ndsPrice: '38,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.3.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '28,33',
            ndsPrice: '34,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.4',
            title: 'ДЕЗИНСЕКЦИЯ муравьёв, мокрицы, чешуйницы, мух, моли, мошкары и др.',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.4.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 600,00',
            ndsPrice: '1 920,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.4.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '31,67',
            ndsPrice: '38,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.4.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '28,33',
            ndsPrice: '34,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.4.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '25,00',
            ndsPrice: '30,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.4.4.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '22,50',
            ndsPrice: '27,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5',
            title: 'ДЕЗИНФЕКЦИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.1',
            title: 'ДЕЗИНФЕКЦИЯ помещений (профилактическая)',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.1.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '600,00',
            ndsPrice: '720,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.1.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '10,00',
            ndsPrice: '12,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.1.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '9,17',
            ndsPrice: '11,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.1.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '8,33',
            ndsPrice: '10,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.1.5',
            title: 'Помещений площадью свыше 1001(кв. м)',
            type: 'кв.метр',
            rawPrice: '7,50',
            ndsPrice: '9,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.2',
            title: 'ДЕЗИНФЕКЦИЯ помещений (в том числе заключительная в очагах инфекционных заболеваний)',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.2.1',
            title: 'В очаге инфекционных заболеваний. Кишечные инфекции, вирусные гепатиты (очаг до 100 кв.м)',
            type: '1 очаг',
            rawPrice: '1 300,00',
            ndsPrice: '1 560,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.2.2',
            title: 'В очаге туберкулеза и грибковых инфекций (очаг до 100 кв.м)',
            type: '1 очаг',
            rawPrice: '3 300,00',
            ndsPrice: '3 960,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.2.3',
            title: 'В очаге чесотки (очаг до 100 кв.м)',
            type: '1 очаг',
            rawPrice: '3 900,00',
            ndsPrice: '4 680,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.2.4',
            title: 'В очаге педикулеза (очаг до 100 кв.м)',
            type: '1 очаг',
            rawPrice: '4 700,00',
            ndsPrice: '5 640,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.2.5',
            title: 'Обработка помещений после трупа',
            type: 'кв.метр',
            rawPrice: '47,50',
            ndsPrice: '57,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.2.6',
            title: 'Обработка помещений от плесени (грибка)',
            type: 'кв.метр',
            rawPrice: '35,00',
            ndsPrice: '42,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.3',
            title: 'ДЕЗИНФЕКЦИЯ неканализованных уборных',
            type: 'объект',
            rawPrice: '1 000,00',
            ndsPrice: '1 200,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.4',
            title: 'ДЕЗИНФЕКЦИЯ предметов',
            type: 'штука',
            rawPrice: '280,00',
            ndsPrice: '336,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.5',
            title: 'ДЕЗИНФЕКЦИЯ ёмкостей, автотранспорта с площадью пола до 15 кв.м',
            type: 'объект',
            rawPrice: '1 200,00',
            ndsPrice: '1 440,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.6',
            title: 'ДЕЗИНФЕКЦИЯ территорий',
            type: 'объект',
            rawPrice: '1 200,00',
            ndsPrice: '1 440,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.7',
            title: 'КАМЕРНАЯ ОБРАБОТКА ВЕЩЕЙ В СТАЦИОНАРНОЙ ДЕЗ.КАМЕРЕ',
            type: 'кв.метр',
            rawPrice: '9,17',
            ndsPrice: '11,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.7.1',
            title: 'В количестве до 3 кг',
            type: '1 обработка',
            rawPrice: '600,00',
            ndsPrice: '720,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.7.2',
            title: 'В количестве от 4 до 15 кг (кг)',
            type: 'кг',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.7.3',
            title: 'В количестве от 15 кг',
            type: 'кг',
            rawPrice: '170,00',
            ndsPrice: '204,00',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.8',
            title: 'ДЕЗИНФЕКЦИЯ  МЕТОДОМ ХОЛОДНОГО ТУМАНА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.8.1',
            title: 'ДЕЗИНФЕКЦИЯ помещений (профилактическая) методом холодного тумана',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.8.1.1',
            title: 'Помещений с площадью до 50 кв. м',
            type: 'объект',
            rawPrice: '1 266,67',
            ndsPrice: '1 520,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.8.1.2',
            title: 'Помещений площадью 51-100 (кв. м)',
            type: 'кв.метр',
            rawPrice: '25,83',
            ndsPrice: '31,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.8.1.3',
            title: 'Помещений площадью 101-500 (кв. м)',
            type: 'кв.метр',
            rawPrice: '25,00',
            ndsPrice: '30,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.8.1.4',
            title: 'Помещений площадью 501-1000 (кв. м)',
            type: 'кв.метр',
            rawPrice: '24,17',
            ndsPrice: '29,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.8.1.5',
            title: 'Помещений площадью свыше 1001 (кв. м)',
            type: 'кв.метр',
            rawPrice: '23,33',
            ndsPrice: '28,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '3.5.8.2',
            title: 'ДЕЗИНФЕКЦИЯ емкостей, автотранспорта с площадью пола до 15 кв.м методом холодного тумана',
            type: 'объект',
            rawPrice: '1 475,00',
            ndsPrice: '1 770,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 4. САНИТАРНО-ЭПИДЕМИОЛОГИЧЕСКОЕ ОБСЛЕДОВАНИЕ',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '4.1',
            title:
              'Водные объекты, используемые в целях питьевого и хозяйственно-бытового водоснабжения, а также в лечебных, оздоровительных и рекреационных целях',
            type: 'акт обследования',
            rawPrice: '14 600,00',
            ndsPrice: '17 520,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '4.2',
            title:
              'Объекты промышленного и общественного назначения, в т.ч. их территории в целях установления соответствия требованиям санитарно-эпидемиологического законодательства',
            type: 'обследование',
            rawPrice: '16 000,00',
            ndsPrice: '19 200,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '4.3',
            title: 'Площадки для размещения контейнеров для сбора ТКО',
            type: 'обследование',
            rawPrice: '4 600,00',
            ndsPrice: '5 520,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 5. ОТБОР ПРОБ ОБЪЕКТОВ ВНЕШНЕЙ СРЕДЫ, ПИЩЕВОЙ ПРОДУКЦИИ И ДР.',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1',
            title: 'ОТБОР ПРОБ ВОДЫ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.1',
            title: 'Централизованного водоснабжения для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '260,00',
            ndsPrice: '312,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.2',
            title: 'Централизованного водоснабжения для микробиологических исследований',
            type: '1 проба',
            rawPrice: '260,00',
            ndsPrice: '312,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.3',
            title: 'Централизованного водоснабжения для вирусологических исследований',
            type: '1 проба',
            rawPrice: '250,00',
            ndsPrice: '300,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.4',
            title: 'Централизованного водоснабжения для паразитологических исследований',
            type: '1 проба',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.5',
            title: 'Централизованного водоснабжения для радиологических исследований',
            type: '1 проба',
            rawPrice: '250,00',
            ndsPrice: '300,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.6',
            title:
              'Централизованного водоснабжения для санитарно-химических, микробиологических, вирусологических, паразитологических, радиологических исследований ',
            type: '1 проба',
            rawPrice: '770,00',
            ndsPrice: '924,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.7',
            title: 'Нецентрализованного водоснабжения (скважина, колодец) для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '280,00',
            ndsPrice: '336,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.8',
            title: 'Нецентрализованного водоснабжения (скважина, колодец) для микробиологических исследований',
            type: '1 проба',
            rawPrice: '280,00',
            ndsPrice: '336,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.9',
            title: 'Нецентрализованного водоснабжения (скважина, колодец) для вирусологических исследований',
            type: '1 проба',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.10',
            title: 'Нецентрализованного водоснабжения (скважина, колодец) для паразитологических исследований',
            type: '1 проба',
            rawPrice: '290,00',
            ndsPrice: '348,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.11',
            title: 'Нецентрализованного водоснабжения (скважина, колодец) для радиологических исследований',
            type: '1 проба',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.12',
            title:
              'Нецентрализованного водоснабжения (скважина, колодец) для санитарно-химических, микробиологических, вирусологических, паразитологических, радиологических исследований ',
            type: '1 проба',
            rawPrice: '830,00',
            ndsPrice: '996,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.13',
            title: 'Поверхностного водоёма для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '300,00',
            ndsPrice: '360,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.14',
            title: 'Поверхностного водоёма для микробиологических исследований',
            type: '1 проба',
            rawPrice: '300,00',
            ndsPrice: '360,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.15',
            title: 'Поверхностного водоёма для вирусологических исследований',
            type: '1 проба',
            rawPrice: '290,00',
            ndsPrice: '348,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.16',
            title: 'Поверхностного водоёма для паразитологических исследований ',
            type: '1 проба',
            rawPrice: '310,00',
            ndsPrice: '372,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.17',
            title: 'Поверхностного водоёма для радиологических исследований',
            type: '1 проба',
            rawPrice: '290,00',
            ndsPrice: '348,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.18',
            title:
              'Поверхностного водоёма для санитарно-химических, микробиологических, вирусологических, паразитологических, радиологических исследований',
            type: '1 проба',
            rawPrice: '890,00',
            ndsPrice: '1 068,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.19',
            title: 'Из чаши бассейна для санитарно-химических исследований ',
            type: '1 проба',
            rawPrice: '300,00',
            ndsPrice: '360,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.20',
            title: 'Из чаши бассейна для микробиологических исследований',
            type: '1 проба',
            rawPrice: '300,00',
            ndsPrice: '360,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.21',
            title: 'Из чаши бассейна для вирусологических исследований',
            type: '1 проба',
            rawPrice: '290,00',
            ndsPrice: '348,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.22',
            title: 'Из чаши бассейна для паразитологических исследований',
            type: '1 проба',
            rawPrice: '310,00',
            ndsPrice: '372,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.23',
            title:
              'Из чаши бассейна для санитарно-химических, микробиологических, вирусологических, паразитологических исследований',
            type: '1 проба',
            rawPrice: '890,00',
            ndsPrice: '1 068,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.24',
            title: 'Сточной для санитарно-химических исследований ',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.25',
            title: 'Сточной для микробиологических исследований',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.26',
            title: 'Сточной для вирусологических исследований',
            type: '1 проба',
            rawPrice: '310,00',
            ndsPrice: '372,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.27',
            title: 'Сточной для паразитологических исследований ',
            type: '1 проба',
            rawPrice: '330,00',
            ndsPrice: '396,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.1.28',
            title:
              'Сточной для санитарное-химических, микробиологических, вирусологических, паразитологических исследований',
            type: '1 проба',
            rawPrice: '950,00',
            ndsPrice: '1 140,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2',
            title: 'ОТБОР ПРОБ ПРОДУКЦИИ ОБЩЕСТВЕННОГО ПИТАНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.1',
            title: 'Первые блюда для микробиологических исследований',
            type: '1 проба',
            rawPrice: '340,00',
            ndsPrice: '408,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.2',
            title: 'Первые блюда для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.3',
            title: 'Первые блюда для микробиологических исследований, санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '550,00',
            ndsPrice: '660,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.4',
            title: 'Гарниры для микробиологических исследований',
            type: '1 проба',
            rawPrice: '340,00',
            ndsPrice: '408,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.5',
            title: 'Гарниры для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.6',
            title: 'Гарниры для микробиологических и санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '550,00',
            ndsPrice: '660,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.7',
            title: 'Мясные и рыбные блюда для микробиологических исследований',
            type: '1 проба',
            rawPrice: '340,00',
            ndsPrice: '408,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.8',
            title: 'Мясные и рыбные блюда для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.9',
            title: 'Мясные и рыбные блюда для микробиологических и санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '550,00',
            ndsPrice: '660,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.10',
            title: 'Салаты для микробиологических исследований',
            type: '1 проба',
            rawPrice: '340,00',
            ndsPrice: '408,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.11',
            title: 'Салаты для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.12',
            title: 'Салаты для микробиологических и санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '550,00',
            ndsPrice: '660,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.13',
            title: 'Морсы и компоты для микробиологических исследований',
            type: '1 проба',
            rawPrice: '340,00',
            ndsPrice: '408,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.14',
            title: 'Морсы и компоты для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.2.15',
            title: 'Морсы и компоты для микробиологических и санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '550,00',
            ndsPrice: '660,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3',
            title: 'ОТБОР ПРОБ ТОВАРОВ НАРОДНОГО ПОТРЕБЛЕНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3.1',
            title: 'Фрукты и овощи для микробиологических исследований',
            type: '1 проба',
            rawPrice: '330,00',
            ndsPrice: '396,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3.2',
            title: 'Фрукты и овощи для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '330,00',
            ndsPrice: '396,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3.3',
            title: 'Фрукты и овощи для микробиологических и санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '550,00',
            ndsPrice: '660,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3.4',
            title: 'Товары в промышленной индивидуальной упаковке для микробиологических исследований',
            type: '1 проба',
            rawPrice: '330,00',
            ndsPrice: '396,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3.5',
            title: 'Товары в промышленной индивидуальной упаковке для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '330,00',
            ndsPrice: '396,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3.6',
            title:
              'Товары в промышленной индивидуальной упаковке для микробиологических и санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '550,00',
            ndsPrice: '660,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3.7',
            title: 'Товары без упаковки (насыпные, разливные, весовые и т.д.) для микробиологических исследований',
            type: '1 проба',
            rawPrice: '330,00',
            ndsPrice: '396,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3.8',
            title: 'Товары без упаковки (насыпные, разливные, весовые и т.д.) для санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '330,00',
            ndsPrice: '396,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.3.9',
            title:
              'Товары без упаковки (насыпные, разливные, весовые и т.д.) для микробиологических и санитарно-химических исследований',
            type: '1 проба',
            rawPrice: '550,00',
            ndsPrice: '660,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4',
            title: 'ОТБОР ПРОБ ПОЧВЫ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.1',
            title: 'Городских и промышленных зон для санитарно-химических исследований; радиологических исследований',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.2',
            title: 'Городских и промышленных зон для микробиологических исследований',
            type: '1 проба',
            rawPrice: '280,00',
            ndsPrice: '336,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.3',
            title: 'Городских и промышленных зон для паразитологических исследований ',
            type: '1 проба',
            rawPrice: '280,00',
            ndsPrice: '336,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.4',
            title:
              'Городских и промышленных зон для санитарно-химических, радиологических, микробиологических, паразитологических исследований',
            type: '1 проба',
            rawPrice: '600,00',
            ndsPrice: '720,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.5',
            title: 'Населенных мест для санитарно-химических исследований; радиологических исследований ',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.6',
            title: 'Населенных мест для микробиологических исследований',
            type: '1 проба',
            rawPrice: '280,00',
            ndsPrice: '336,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.7',
            title: 'Населенных мест для паразитологических исследований ',
            type: '1 проба',
            rawPrice: '280,00',
            ndsPrice: '336,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.8',
            title:
              'Населенных мест для санитарно-химических, радиологических, микробиологических, паразитологических исследований',
            type: '1 проба',
            rawPrice: '600,00',
            ndsPrice: '720,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.9',
            title:
              'С пахотных земель, сенокосов, пастбищ, лесных питомников для санитарно-химических исследований; радиологических исследований',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.10',
            title: 'С пахотных земель, сенокосов, пастбищ, лесных питомников для микробиологических исследований',
            type: '1 проба',
            rawPrice: '280,00',
            ndsPrice: '336,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.11',
            title: 'С пахотных земель, сенокосов, пастбищ, лесных питомников для паразитологических исследований',
            type: '1 проба',
            rawPrice: '280,00',
            ndsPrice: '336,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.4.12',
            title:
              'С пахотных земель, сенокосов, пастбищ, лесных питомников для санитарно-химических, радиологических, микробиологических, паразитологических исследований',
            type: '1 проба',
            rawPrice: '700,00',
            ndsPrice: '840,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.5',
            title: 'ОТБОР ПРОБ ВОЗДУХА ДЛЯ МИКРОБИОЛОГИЧЕСКИХ ИССЛЕДОВАНИЙ САНИТАРНО-ГИГИЕНИЧЕСКИХ ИССЛЕДОВАНИЙ',
            type: '1 проба',
            rawPrice: '260,00',
            ndsPrice: '312,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.6',
            title: 'ОТБОР ПРОБ МЕТОДОМ СМЫВОВ:',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.6.1',
            title:
              'Предметы, мебель, оборудование лечебных и других учреждений для микробиологических исследований 5 смывов',
            type: '1 услуга',
            rawPrice: '300,00',
            ndsPrice: '360,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.6.2',
            title: 'Спецодежда персонала для микробиологических исследований 5 смывов',
            type: '1 услуга',
            rawPrice: '300,00',
            ndsPrice: '360,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.6.3',
            title: 'Руки персонала для микробиологических исследований 5 смывов',
            type: '1 услуга',
            rawPrice: '300,00',
            ndsPrice: '360,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.6.4',
            title:
              'Предметы, мебель, оборудование лечебных и других учреждений, спецодежда, руки персонала для микробиологических исследований 15 смывов',
            type: '1 услуга',
            rawPrice: '700,00',
            ndsPrice: '840,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.7',
            title: 'ОТБОР ПРОБ ДЛЯ ЭНТОМОЛОГИЧЕСКИХ ИССЛЕДОВАНИЙ (ПОЧВА, ПЕСОК, ПЫЛЬ, БИОЛОГИЧЕСКИЙ МАТЕРИАЛ',
            type: '1 проба',
            rawPrice: '320,00',
            ndsPrice: '384,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.8',
            title:
              'ОТБОР ПРОБ НА СТЕРИЛЬНОСТЬ (предметы и материалы медицинского назначения для микробиологических исследований)',
            type: '1 проба',
            rawPrice: '100,00',
            ndsPrice: '120,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.9',
            title: 'ВЫДАЧА ЗАВЕРРЕННОЙ КОПИИ ПРОТОКОЛА ЛАБОРАТОРНЫХ ИССЛЕДОВАНИЙ',
            type: 'копия',
            rawPrice: '50,00',
            ndsPrice: '60,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.10',
            title: 'ВЫДАЧА ДОПОЛНИТЕЛЬНОГО ЭКЗЕМПЛЯРА ПРОТОКОЛА ЛАБОРАТОРНЫХ ИССЛЕДОВАНИЙ',
            type: 'протокол',
            rawPrice: '100,00',
            ndsPrice: '120,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '5.11',
            title:
              'ПЕРЕОФОРМЛЕНИЕ ПРОТОКОЛА ЛАБОРАТОРНЫХ ИССЛЕДОВАНИЙ (внесение изменений по запросу Заказчика, в случае отсутствия вины Исполнителя)',
            type: 'протокол',
            rawPrice: '200,00',
            ndsPrice: '240,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 6. САНИТАРНО-ХИМИЧЕСКИЕ ИССЛЕДОВАНИЯ',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '6.1',
            title: 'ВОДА ДИСТИЛЛИРОВАННАЯ, ВОДА ДЛЯ ЛАБОРАТОРНОГО АНАЛИЗА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.1.1',
            title: 'Вещества, восстанавливающие KMnO4 (визуальным методом)',
            type: 'Исследование',
            rawPrice: '155,00',
            ndsPrice: '186,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.1.2',
            title: 'рН (потенциометрическим методом)',
            type: 'Исследование',
            rawPrice: '126,67',
            ndsPrice: '152,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.1.3',
            title: 'Удельная электропроводность (кондуктометрическим методом)',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.1.4',
            title: 'Внешний вид, запах',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.1.5',
            title: 'Аммиак, алюминий и нитраты (фотометрическим методом) за 1 показатель',
            type: 'Исследование',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2',
            title:
              'ВОДА ПИТЬЕВАЯ, КАК РАСФАСОВАННАЯ, ТАК И ИЗ ИСТОЧНИКОЙ ЦЕНТРАЛИЗОВАННОГО И НЕЦЕНТРАЛИЗОВАННОГО ВОДОСНАБЖЕНИЯ, В ТОМ ЧИСЛЕ ГОРЯЧЕГО ВОДОСНАБЖЕНИЯ, СТОЧНАЯ И ТЕХНИЧЕСКАЯ ВОДА, ВОДА ОТКРЫТЫХ ВОДОЁМОВ И ПЛАВАТЕЛЬНЫХ БАССЕЙНОВ, ВОДА ИЗ СИСТЕМЫ КОНДИЦИОНИРОВАНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.1',
            title: 'Мышьяк (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '385,00',
            ndsPrice: '462,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.2',
            title: 'Кальций титрометрическим методом',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.3',
            title: 'рН (потенциометрическим методом)',
            type: 'Исследование',
            rawPrice: '126,67',
            ndsPrice: '152,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.4',
            title: 'Алюминий спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.5',
            title: 'Аммиак спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.6',
            title: 'Бор (суммарно) спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.7',
            title: 'БПК5 (йодометрическим методом)',
            type: 'Исследование',
            rawPrice: '476,67',
            ndsPrice: '572,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.8',
            title: 'БПК полное (йодометрическим методом)',
            type: 'Исследование',
            rawPrice: '605,00',
            ndsPrice: '726,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.9',
            title: 'Взвешенные вещества (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.10',
            title: 'Железо спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.11',
            title: 'Жесткость общая комплексонометрическим (титрометрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.12',
            title: 'Кремний спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.13',
            title: 'Магний (расчетным методом)',
            type: 'Исследование',
            rawPrice: '126,67',
            ndsPrice: '152,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.14',
            title: 'Массовая доля растворенной двуокиси углерода',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.15',
            title:
              'Металлы (марганец, медь, молибден, селен) спектрофотометрическим (фотометрическим методом) за 1 элемент',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.16',
            title: 'Мутность спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '155,00',
            ndsPrice: '186,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.17',
            title: 'Нефтепродукты (весовым методом)',
            type: 'Исследование',
            rawPrice: '822,50',
            ndsPrice: '987,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.18',
            title: 'Нитраты спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.19',
            title: 'Нитриты спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.20',
            title: 'Озон (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.21',
            title: 'Окисляемость перманганатная (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '322,50',
            ndsPrice: '387,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.22',
            title: 'Органолептические показатели:',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.22.1',
            title: 'Запах',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.22.2',
            title: 'Привкус',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.22.3',
            title: 'Вкус',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.23',
            title: 'ПАВ спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.24',
            title: 'Плавающие примеси',
            type: 'Исследование',
            rawPrice: '126,67',
            ndsPrice: '152,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.25',
            title: 'Полифосфаты ортофосфаты (фотометрическим методом) за 1 показатель',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.26',
            title: 'Растворенный кислород (йодометрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.27',
            title: 'Сероводород (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.28',
            title: 'Сульфаты весовым (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '438,33',
            ndsPrice: '526,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.29',
            title: 'Сухой остаток (общая минерализация) (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '476,67',
            ndsPrice: '572,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.30',
            title: 'Температура',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.32',
            title: 'Удельная электропроводность (кондуктометрическим методом)',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.33',
            title: 'Фенол спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.34',
            title: 'Формальдегид спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.36',
            title: 'Фториды (фтор) спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.37',
            title: 'Хлор остаточный активный (йодометрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.38',
            title: 'Хлор свободный (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.39',
            title: 'Хлор связанный (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.40',
            title: 'Хлориды (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '361,67',
            ndsPrice: '434,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.41',
            title: 'Хром общий спектрофотометрическим (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.44',
            title: 'Цветность спектрофотометрическим (фотометрическим) методом',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.45',
            title: 'Щелочность (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '205,00',
            ndsPrice: '246,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.46',
            title: 'Окраска столбика воды',
            type: 'Исследование',
            rawPrice: '155,00',
            ndsPrice: '186,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.47',
            title:
              'Тяжелые металлы (свинец, цинк, марганец, медь, кадмий, никель и др.), токсичные элементы методом атомной абсорбции (1 элемент)',
            type: 'Исследование',
            rawPrice: '600,00',
            ndsPrice: '720,00',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.48',
            title:
              'Определение массовой концентрации катионов аммония, калия, натрия, лития, магния, стронция, бария и кальция методом капиллярного электрофореза (за один показатель)',
            type: 'Исследование',
            rawPrice: '349,17',
            ndsPrice: '419,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.49',
            title:
              'Определение массовой  концентрации хлорид-ионов, нитрит-ионов, сульфат-ионов, нитрат-ионов, фторид-ионов, фосфат-ионов методом капиллярного электрофореза (за один показатель)',
            type: 'Исследование',
            rawPrice: '431,67',
            ndsPrice: '518,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.50',
            title: 'Пестициды: ГХЦГ (α, β, ϒ - изомеры) методом ГХ',
            type: 'Исследование',
            rawPrice: '2 350,00',
            ndsPrice: '2 820,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.51',
            title: 'Пестициды (1 наименование) методом ГХ',
            type: 'Исследование',
            rawPrice: '1 133,33',
            ndsPrice: '1 360,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.52',
            title: 'Органолептические  показатели (запах, привкус, цветность, мутность)',
            type: 'Исследование',
            rawPrice: '791,67',
            ndsPrice: '950,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.2.53',
            title:
              'Краткий химический анализ (pH, железо, жесткость общая, сухой остаток, анионы)                                                     ',
            type: 'Исследование',
            rawPrice: '3 650,00',
            ndsPrice: '4 380,00',
          },

          {
            id: uuidv4(),
            searchId: '',
            number: '6.3',
            title: 'ПОЧВА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.3.2',
            title: 'рН (потенциометрическим методом)',
            type: 'Исследование',
            rawPrice: '205,00',
            ndsPrice: '246,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.3.3',
            title: 'Влажность (методом высушивания)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.3.4',
            title: 'Нитраты (ионоселективным методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.3.5',
            title: 'Сульфат-ион (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '476,67',
            ndsPrice: '572,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.3.7',
            title: 'Хлориды (аргентометрическим методом)',
            type: 'Исследование',
            rawPrice: '361,67',
            ndsPrice: '434,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.3.8',
            title:
              'Тяжелые металлы (свинец, цинк,марганец, медь, кадмий, никель и др.), токсичные элементы методом атомной абсорбции (1 элемент)',
            type: 'Исследование',
            rawPrice: '741,67',
            ndsPrice: '890,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.3.9',
            title: 'Пестициды: ГХЦ (α, β, ϒ - изомеры) методом  ГХ',
            type: 'Исследование',
            rawPrice: '2 483,33',
            ndsPrice: '2 980,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.3.10',
            title: 'Пестициды (1 наименование) методом ГХ',
            type: 'Исследование',
            rawPrice: '1 283,33',
            ndsPrice: '1 540,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4',
            title: 'АТМОСФЕРНЫЙ ВОЗДУХ (1 показатель в одной точке)',
            type: 'Исследование',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.1',
            title: 'Вещество на газоанализаторе',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.4',
            title: 'Фенол (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '425,00',
            ndsPrice: '510,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.5',
            title: 'Формальдегид (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '425,00',
            ndsPrice: '510,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.6',
            title: 'Азота диоксид (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.7',
            title: 'Фтористый водород (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.8',
            title: 'Сероводород (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.9',
            title: 'Аммиак (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '425,00',
            ndsPrice: '510,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.10',
            title: 'Хлористый водород (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '425,00',
            ndsPrice: '510,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.11',
            title: 'Сернистый ангидрид (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '425,00',
            ndsPrice: '510,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.12',
            title: 'Свинец (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '425,00',
            ndsPrice: '510,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.13',
            title: 'Марганец (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '425,00',
            ndsPrice: '510,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.14',
            title: 'Серная кислота (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '425,00',
            ndsPrice: '510,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.15',
            title: 'Хлор (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '425,00',
            ndsPrice: '510,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.16',
            title: 'Пары ртути (беспламенным атомно-абсорбционным методом)',
            type: 'Исследование',
            rawPrice: '554,17',
            ndsPrice: '665,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.4.17',
            title: 'Пыль, сажа в атмосферном воздухе с помощью фильтров',
            type: 'Исследование',
            rawPrice: '528,33',
            ndsPrice: '634,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.5',
            title: 'ВОЗДУХ РАБОЧЕЙ ЗОНЫ И ЗАКРЫТЫХ ПОМЕЩЕНИЙ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.5.2',
            title:
              'Металлы и другие органические и неорганические вещества спектрофотометрическим (фотометрическим) методом за 1 показатель',
            type: 'Исследование',
            rawPrice: '1 600,00',
            ndsPrice: '1 920,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.5.4',
            title: 'Пыль (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '451,67',
            ndsPrice: '542,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.5.6',
            title: 'Пары ртути (беспламенным атомно-абсорбционным методом)',
            type: 'Исследование',
            rawPrice: '554,17',
            ndsPrice: '665,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.5.7',
            title: 'Вещество на газоанализаторе',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.6',
            title: 'ДЕЗИНФИЦИРУЮЩИЕ СРЕДСТВА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.6.1',
            title:
              'Исследование дезинфицирующих средств на процентное содержание действующих (активных) веществ (титрометрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7',
            title: 'ПИШЕВЫЕ ПРОДУКТЫ, НАПИТКИ, В Т.Ч. АЛКОГОЛЬНЫЕ, ТОВАРЫ НАРОДНОГО ПОТРЕБЛЕНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.4',
            title:
              'Хлористый натрий в плодово-овощной продукции, молоке, мясе, молочной, мясной и рыбной продукции (титрометрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.5',
            title: 'Влага, массовой доли влаги в рыбе, жировой продукции и др. (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.6',
            title: 'Фосфаты в мясе и мясных продуктах (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.7',
            title: 'Исследование массовой части фарша (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.8',
            title: 'Пористость (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.9',
            title: 'Гидрокарбонат-ионы в безалкогольных напитках и воде минеральной (титрометрическим методом)',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.10',
            title: 'Жир в хлебобулочных и кондитерских изделиях (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.11',
            title: 'Нитрит-ионы в безалкогольных напитках и воде минеральной (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.12',
            title: 'Нитрат-ионы в безалкогольных напитках и воде минеральной (колориметрическим методом)',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.13',
            title: 'Ионы железа в безалкогольных напитках и воде минеральной (титрометрическим методом)',
            type: 'Исследование',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.14',
            title:
              'Окисляемость перманганатная в безалкогольных напитках и воде минеральной (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '322,50',
            ndsPrice: '387,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.16',
            title: 'Сухие вещества (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.17',
            title: ' рН (потенциометрическим методом)',
            type: 'Исследование',
            rawPrice: '205,00',
            ndsPrice: '246,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.18',
            title: 'Аммиак в молоке (качественный анализ)',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.19',
            title: 'Массовая доля белка в мясе и мясной продукции (методом Кьельдаля)',
            type: 'Исследование',
            rawPrice: '846,67',
            ndsPrice: '1 016,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.20',
            title: 'Витамин С в сухих молочных детских продуктах фотометрическим методом',
            type: 'Исследование',
            rawPrice: '680,00',
            ndsPrice: '816,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.21',
            title: 'Витамин С в плодово-овощной продукции (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '255,00',
            ndsPrice: '306,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.22',
            title: 'Готовность продукта к употреблению (органолептическим методом)',
            type: 'Исследование',
            rawPrice: '155,00',
            ndsPrice: '186,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.23',
            title: 'Двуокись углерода в безалкогольных и алкогольных напитках (манометрическим методом)',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.24',
            title: 'Жир с пересчетом на сухое вещество при необходимости (методом Гербера)',
            type: 'Исследование',
            rawPrice: '603,33',
            ndsPrice: '724,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.25',
            title: 'Жир с пересчетом на сухое вещество при необходимости (методом Сокслета)',
            type: 'Исследование',
            rawPrice: '745,00',
            ndsPrice: '894,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.26',
            title: 'Жир в молоке и молочной продукции (кислотным методом)',
            type: 'Исследование',
            rawPrice: '346,67',
            ndsPrice: '416,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.27',
            title:
              'Качественный тест на добавленные компоненты с углеводами в рубленых мясных полуфабрикатах: хлеб, картофель, каша',
            type: 'Исследование',
            rawPrice: '228,33',
            ndsPrice: '274,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.28',
            title: 'Эффективность тепловой обработки пищевой продукции (фосфатаза, пероксидаза)',
            type: 'Исследование',
            rawPrice: '228,33',
            ndsPrice: '274,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.29',
            title: 'Кислотность, в том числе жировой фазы, молока и молочных продуктов (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '257,50',
            ndsPrice: '309,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.30',
            title: 'Кислотность по болтушке титриметрическим методом',
            type: 'Исследование',
            rawPrice: '243,33',
            ndsPrice: '292,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.31',
            title: 'Кислотность титруемая в плодово-овощной продукции (титрометрическим методом)',
            type: 'Исследование',
            rawPrice: '255,00',
            ndsPrice: '306,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.32',
            title:
              'Кислотность в хлебобулочных, кондитерских изделиях, безалкогольных напитках и пиве (титрометрическим методом)',
            type: 'Исследование',
            rawPrice: '257,50',
            ndsPrice: '309,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.33',
            title: 'Клейковина: количество и качество (методом отмывания)',
            type: 'Исследование',
            rawPrice: '449,17',
            ndsPrice: '539,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.34',
            title: 'Консерванты титриметрическим методом (1 показатель)',
            type: 'Исследование',
            rawPrice: '449,17',
            ndsPrice: '539,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.35',
            title: 'Костные включения массовая доля',
            type: 'Исследование',
            rawPrice: '834,17',
            ndsPrice: '1 001,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.36',
            title: 'Массовая доля крахмала в мясе и мясных продуктах (йодометрическим, титрометрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.37',
            title: 'Масса нетто',
            type: 'Исследование',
            rawPrice: '153,33',
            ndsPrice: '184,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.38',
            title: 'Массовая доля золы (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '346,67',
            ndsPrice: '416,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.39',
            title: 'Минеральные примеси (органолептическим методом)',
            type: 'Исследование',
            rawPrice: '155,00',
            ndsPrice: '186,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.40',
            title: 'Нитраты в плодово-овощной продукции (ионометрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.41',
            title: 'Нитрит натрия в мясе и мясных продуктах (фотометрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.42',
            title:
              'Органолептические показатели пищевой продукции в зависимости от вида продукции (цвет, вкус, запах, консистенция, внешний вид и др.)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.43',
            title:
              'Органолептические показатели на подтверждение сроков годности (с учётом кратности и установленной периодичностью - минимум 3 раза)',
            type: 'Исследование',
            rawPrice: '988,33',
            ndsPrice: '1 186,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.44',
            title: 'Отношение составных частей плодово-овощной продукции (гравиметрическим методом)',
            type: 'Исследование',
            rawPrice: '243,33',
            ndsPrice: '292,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.45',
            title: 'Отстой в масле – массовая доля',
            type: 'Исследование',
            rawPrice: '346,67',
            ndsPrice: '416,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.46',
            title: 'Отстой в масле – объемная доля',
            type: 'Исследование',
            rawPrice: '396,67',
            ndsPrice: '476,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.47',
            title: 'Фосфатаза в молоке и молочной продукции - контроль пастеризации',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.48',
            title:
              'Перекисное число в продуктах, не требующих предварительного извлечения жира (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '346,67',
            ndsPrice: '416,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.49',
            title:
              'Перекисное число в продуктах, требующих предварительного извлечения жира (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '396,67',
            ndsPrice: '476,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.50',
            title: 'Пероксидаза (перекись водорода) в молоке (качественный анализ)',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.52',
            title: 'Плотность молока и молочной продукции (ареометрическим, пикнометрическим методом)',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.53',
            title: 'Сахар (методом горячего титрования)',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.54',
            title: 'Сахар в молоке (йодометрическим методом)',
            type: 'Исследование',
            rawPrice: '396,67',
            ndsPrice: '476,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.55',
            title: 'Сахар (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.56',
            title: 'Свежесть (органолептическим методом)',
            type: 'Исследование',
            rawPrice: '155,00',
            ndsPrice: '186,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.57',
            title: 'Сода в молоке',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.58',
            title:
              'Сухие вещества в готовых продуктах, безалкогольных и алкогольных напитках (рефрактометрическим методом)',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.59',
            title: 'Тяжелые металлы: свинец, цинк, кадмий, медь (полярографическим методом) 1 элемент',
            type: 'Исследование',
            rawPrice: '590,00',
            ndsPrice: '708,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.60',
            title: 'Массовая доля хлеба в мясе и мясных продуктах (йодометрическим методом)',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.61',
            title: 'Группа чистоты молока, молочных и молокосодержащих консервов',
            type: 'Исследование',
            rawPrice: '205,00',
            ndsPrice: '246,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.62',
            title: 'Щелочность в кондитерских изделиях (титриметрическим методом)',
            type: 'Исследование',
            rawPrice: '205,00',
            ndsPrice: '246,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.63',
            title:
              'Энергетическая ценность расчетным методом при наличии в счете: белки+жир по Сокслету+углеводы+сухие вещества+масса блюда нетто',
            type: 'Исследование',
            rawPrice: '340,00',
            ndsPrice: '408,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.64',
            title: 'Этиловый спирт (крепость) ареометрическим методом',
            type: 'Исследование',
            rawPrice: '254,17',
            ndsPrice: '305,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.65',
            title:
              'Определение углеводов расчётным методом (при наличии в счёте определения сухого вещества термогравиметрическим методом, белка, жира по Сокслету, золы.)',
            type: 'Исследование',
            rawPrice: '286,67',
            ndsPrice: '344,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.66',
            title:
              'Тяжелые металлы (свинец, цинк, медь, кадмий, никель и др.), токсичные элементы методом атомной абсорбции (1 элемент)',
            type: 'Исследование',
            rawPrice: '741,67',
            ndsPrice: '890,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.67',
            title: 'Пестициды: ДДТ  и его метаболиты методом ГХ  ',
            type: 'Исследование',
            rawPrice: '1 391,67',
            ndsPrice: '1 670,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.68',
            title: 'Пестициды:  ГХЦГ (α, β, ϒ  - изомеры) методом ГХ ',
            type: 'Исследование',
            rawPrice: '2 450,00',
            ndsPrice: '2 940,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '6.7.69',
            title: 'Пестициды (1 наименование) методом ГХ',
            type: 'Исследование',
            rawPrice: '1 266,67',
            ndsPrice: '1 520,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 7. НЕИОНИЗИРУЮЩИЕ ИЗЛУЧЕНИЯ (ФИЗИЧЕСКИЕ ФАКТОРЫ)',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1',
            title: 'ШУМ И ВИБРАЦИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.1',
            title: 'Вибрация общая - за 1 точку (одно событие)',
            type: 'Исследование',
            rawPrice: '1 157,50',
            ndsPrice: '1 389,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.2',
            title: 'Вибрация локальная- за 1 точку (одно событие)',
            type: 'Исследование',
            rawPrice: '1 157,50',
            ndsPrice: '1 389,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.3',
            title: 'Вибрация за рабочее место; помещение',
            type: 'Исследование',
            rawPrice: '4 948,33',
            ndsPrice: '5 938,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.4',
            title: 'Шум в одной точке в помещении',
            type: 'Исследование',
            rawPrice: '1 478,33',
            ndsPrice: '1 774,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.5',
            title: 'Шум в трёх точках (по одному адресу) на территории',
            type: 'Исследование',
            rawPrice: '4 433,33',
            ndsPrice: '5 320,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.6',
            title: 'Шум на рабочем месте в помещении',
            type: 'Исследование',
            rawPrice: '4 948,33',
            ndsPrice: '5 938,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.7',
            title: 'Инфразвук одно измерение (одно шумовое событие)',
            type: 'Исследование',
            rawPrice: '1 478,33',
            ndsPrice: '1 774,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.8',
            title: 'Инфразвук за рабочее место/помещение',
            type: 'Исследование',
            rawPrice: '4 948,33',
            ndsPrice: '5 938,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.9',
            title: 'Ультразвук одно измерение (одно шумовое событие)',
            type: 'Исследование',
            rawPrice: '1 478,33',
            ndsPrice: '1 774,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.1.10',
            title: 'Ультразвук за рабочее место/ помещение',
            type: 'Исследование',
            rawPrice: '4 948,33',
            ndsPrice: '5 938,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2',
            title: 'ЭЛЕКТРОМАГНИТНЫЕ ПОЛЯ (ЭМП) РАДИОЧАСТОТНОГО ДИАПАЗОНА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.1',
            title: 'ЭМП радиочастотного диапазона - одно измерение одной антенной: от одной до 50 точек на 1 объекте',
            type: 'Исследование',
            rawPrice: '1 253,33',
            ndsPrice: '1 504,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.2',
            title: 'ЭМП радиочастотного диапазона - одно измерение одной антенной: с 51-ой точки и более на 1 объекте',
            type: 'Исследование',
            rawPrice: '1 253,33',
            ndsPrice: '1 504,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.3',
            title: 'ЭМП радиочастотного диапазона - за 1 рабочее место',
            type: 'Исследование',
            rawPrice: '3 778,33',
            ndsPrice: '4 534,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.4',
            title:
              'Электрические и магнитные поля переменного тока промышленной частоты (50Гц) за рабочее место в помещении',
            type: 'Исследование',
            rawPrice: '3 416,67',
            ndsPrice: '4 100,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.5',
            title: 'Электрические поля переменного тока промышленной частоты (50Гц) за 1 точку',
            type: 'Исследование',
            rawPrice: '1 253,33',
            ndsPrice: '1 504,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.6',
            title: 'Магнитные поля переменного тока промышленной частоты (50Гц) за 1 точку',
            type: 'Исследование',
            rawPrice: '1 253,33',
            ndsPrice: '1 504,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.7',
            title: 'Постоянное магнитное поле- за 1 точку',
            type: 'Исследование',
            rawPrice: '1 253,33',
            ndsPrice: '1 504,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.8',
            title: 'Электростатическое поле или потенциал (ЭСП) за 1 точку',
            type: 'Исследование',
            rawPrice: '1 253,33',
            ndsPrice: '1 504,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.9',
            title: 'Электростатическое поле или потенциал (ЭСП) за рабочее место; помещение',
            type: 'Исследование',
            rawPrice: '4 327,50',
            ndsPrice: '5 193,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.2.10',
            title: 'Напряженность электростатического поля (электризуемость) материала и изделий',
            type: 'Исследование',
            rawPrice: '4 327,50',
            ndsPrice: '5 193,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.3',
            title: 'ОСВЕЩЁННОСТЬ (за одну точку, рабочую поверхность)',
            type: 'Исследование',
            rawPrice: '385,00',
            ndsPrice: '462,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.4',
            title: 'ОСВЕЩЁННОСТЬ (за помещение)',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.4.1',
            title: 'Длина помещения не более 5м',
            type: 'Исследование',
            rawPrice: '2 826,67',
            ndsPrice: '3 392,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.4.2',
            title: 'Длина помещения более 5м и не более 10м',
            type: 'Исследование',
            rawPrice: '3 212,50',
            ndsPrice: '3 855,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.4.3',
            title: 'Длина помещения более 10м и не более 25м',
            type: 'Исследование',
            rawPrice: '3 598,33',
            ndsPrice: '4 318,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.4.4',
            title: 'Длина помещения более 25м и не более 50м',
            type: 'Исследование',
            rawPrice: '4 562,50',
            ndsPrice: '5 475,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.4.5',
            title: 'Длина помещения более 50м и не более 100м',
            type: 'Исследование',
            rawPrice: '5 140,00',
            ndsPrice: '6 168,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.5',
            title: 'ЯРКОСТЬ (за 1 точку, рабочую поверхность)',
            type: 'Исследование',
            rawPrice: '385,00',
            ndsPrice: '462,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.6',
            title: 'КОЭФФИЦЕНТ ПУЛЬСАЦИИ (за 1 точку, рабочую поверхность)',
            type: 'Исследование',
            rawPrice: '385,00',
            ndsPrice: '462,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.7',
            title: 'КОЭЭФИЦИЕНТ ПУЛЬСАЦИИ (за помещение)',
            type: 'Исследование',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.7.1',
            title: 'Длина помещения не более 5м',
            type: 'Исследование',
            rawPrice: '2 826,67',
            ndsPrice: '3 392,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.7.2',
            title: 'Длина помещения более 5м и не более 10м',
            type: 'Исследование',
            rawPrice: '3 212,50',
            ndsPrice: '3 855,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.7.3',
            title: 'Длина помещения более 10м и не более 25м',
            type: 'Исследование',
            rawPrice: '3 598,33',
            ndsPrice: '4 318,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.7.4',
            title: 'Длина помещения более 25м и не более 50м',
            type: 'Исследование',
            rawPrice: '4 562,50',
            ndsPrice: '5 475,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.7.5',
            title: 'Длина помещения более 50м и не более 100м',
            type: 'Исследование',
            rawPrice: '5 140,00',
            ndsPrice: '6 168,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.8',
            title: 'ИЗМЕРЕНИЕ ДВУХ ПОКАЗАТЕЛЕЙ - ОСВЕЩЁННОСТЬ, КОЭФФИЦИЕНТ ПУЛЬСАЦИИ (за 1 точку, рабочую поверхность)',
            type: 'Исследование',
            rawPrice: '682,50',
            ndsPrice: '819,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.9',
            title: 'ИЗМЕРЕНИЕ ДВУХ ПОКАЗАТЕЛЕЙ - ОСВЕЩЁННОСТЬ, КОЭФФИЦИЕНТ ПУЛЬСАЦИИ (за помещении)',
            type: 'Исследование',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.9.1',
            title: 'Длина помещения не более 5м',
            type: 'Исследование',
            rawPrice: '3 443,33',
            ndsPrice: '4 132,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.9.2',
            title: 'Длина помещения более 5м и не более 10м',
            type: 'Исследование',
            rawPrice: '4 306,67',
            ndsPrice: '5 168,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.9.3',
            title: 'Длина помещения более 10м и не более 25м',
            type: 'Исследование',
            rawPrice: '5 164,17',
            ndsPrice: '6 197,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.9.4',
            title: 'Длина помещения более 25м и не более 50м',
            type: 'Исследование',
            rawPrice: '7 312,50',
            ndsPrice: '8 775,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.9.5',
            title: 'Длина помещения более 50м и не более 100м',
            type: 'Исследование',
            rawPrice: '8 608,33',
            ndsPrice: '10 330,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.10',
            title:
              'ИЗМЕРЕНИЕ ТРЁХ ПОКАЗАТЕЛЕЙ - ОСВЕЩЁННОСТЬ, ЯРКОСТЬ, КОЭФФИЦИЕНТ ПУЛЬСАЦИИ (за 1 точку, рабочую поверхность)',
            type: 'Исследование',
            rawPrice: '990,00',
            ndsPrice: '1 188,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.11',
            title: 'УЛЬТРАФИОЛЕТОВОЕ ИЗЛУЧЕНИЕ УФ-А, УФ-В и УФ-С за 1 точку',
            type: 'Исследование',
            rawPrice: '990,00',
            ndsPrice: '1 188,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.12',
            title: 'УЛЬТРАФИОЛЕТОВОЕ ИЗЛУЧЕНИЯ УФ-А, УФ-В и УФ-С за рабочее место; помещение',
            type: 'Исследование',
            rawPrice: '1 926,67',
            ndsPrice: '2 312,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.13',
            title: 'АЭРОИОНЫ (за одно рабочее место)',
            type: 'Исследование',
            rawPrice: '990,00',
            ndsPrice: '1 188,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.14',
            title:
              'МИКРОКЛИМАТ (температура, влажность, скорость движения воздуха) за одно рабочее место при количестве рабочих мест от 1 до 5',
            type: 'Исследование',
            rawPrice: '2 506,67',
            ndsPrice: '3 008,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.15',
            title:
              'МИКРОКЛИМАТ (температура, влажность, скорость движения воздуха) за одно рабочее место при количестве рабочих мест от 6 до 10',
            type: 'Исследование',
            rawPrice: '2 311,67',
            ndsPrice: '2 774,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.16',
            title:
              'МИКРОКЛИМАТ (температура, влажность, скорость движения воздуха) за одно рабочее место при количестве рабочих мест от 11 и более',
            type: 'Исследование',
            rawPrice: '2 122,50',
            ndsPrice: '2 547,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.17',
            title: 'ТЕМПЕРАТУРЫ И ВЛАЖНОСТИ (за одно рабочее место)',
            type: 'Исследование',
            rawPrice: '1 543,33',
            ndsPrice: '1 852,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.18',
            title:
              'МИКРОКЛИМАТА (температура, влажность, скорость движения воздуха) за одно помещение (в общественном здании) до 100 кв.м.',
            type: 'Исследование',
            rawPrice: '1 285,00',
            ndsPrice: '1 542,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.19',
            title: 'ТЕМПЕРАТУРА И ВЛАЖНОСТЬ за одно помещение (в общественном здании) до 100 кв.м.',
            type: 'Исследование',
            rawPrice: '863,33',
            ndsPrice: '1 036,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.20',
            title: 'ТЕПЛОВОЕ ИЗЛУЧЕНИЕ за одно рабочее место',
            type: 'Исследование',
            rawPrice: '990,00',
            ndsPrice: '1 188,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.21',
            title: 'ТЕМПЕРАТУРА одной поверхности',
            type: 'Исследование',
            rawPrice: '540,00',
            ndsPrice: '648,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.22',
            title: 'ТЕМПЕРАТУРА ГОРЯЧЕЙ ВОДЫ (от одного образца)',
            type: 'Исследование',
            rawPrice: '150,00',
            ndsPrice: '180,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.23',
            title: 'ИНСТРУМЕНТАЛЬНОЕ ИССЛЕДОВАНИЕ ФИЗИЧЕСКИХ ФАКТОРОВ НА ОТДЕЛЬНЫХ ТИПОВЫХ РАБОЧИХ МЕСТАХ:',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.23.1',
            title: 'ЭМП на одном рабочем месте, оборудованном ПЭВМ',
            type: 'Исследование',
            rawPrice: '1 999,17',
            ndsPrice: '2 399,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.23.2',
            title: 'ЭМП в одном отделении МРТ',
            type: 'Исследование',
            rawPrice: '19 999,17',
            ndsPrice: '23 999,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.23.3',
            title: 'ЭМП на одном рабочем месте в отделении физиотерапии',
            type: 'Исследование',
            rawPrice: '9 999,17',
            ndsPrice: '11 999,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '7.23.4',
            title: 'ЭМП в одной точке (измерение одной антенной - преобразователем) в отделении физиотерапии',
            type: 'Исследование',
            rawPrice: '1 199,17',
            ndsPrice: '1 439,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 8. ИОНИЗИРУЮЩИЕ ИЗЛУЧЕНИЯ (РАДИОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ И ОТЧЁТЫ)',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '8.1',
            title:
              'ИССЛЕДОВАНИЕ РАДИОНУКЛИДНОГО СОСТАВА И УДЕЛЬНОЙ АКТИВНОСТИ ЕСТЕСТВЕННЫХ И ИСКУССТВЕННЫХ РАДИОНУКЛИДОВ В ОБЪЕКТАХ ОКРУЖАЮЩЕЙ СРЕДЫ (с применением γ-спектрометра)',
            type: 'Исследование',
            rawPrice: '600,00',
            ndsPrice: '720,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '8.2',
            title: 'ИЗМЕРЕНИЕ МОЩНОСТИ ДОЗЫ ГАММА-ИЗЛУЧЕНИЯ В ВОЗДУХЕ',
            type: 'Исследование',
            rawPrice: '216,67',
            ndsPrice: '260,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '8.3',
            title: 'СПЕКТРОМЕТРИЧЕСКОЕ ИССЛЕДОВАНИЕ ПИЩЕВЫХ ПРОДУКТОВ НА СОДЕРЖАНИЕ ЦЕЗИЙ-137',
            type: 'Исследование',
            rawPrice: '500,00',
            ndsPrice: '600,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '8.4',
            title: 'СПЕКТРОМЕТРИЧЕСКОЕ ИССЛЕДОВАНИЕ ПИЩЕВЫХ ПРОДУКТОВ НА СОДЕРЖАНИЕ СТРОНЦИЙ-90',
            type: 'Исследование',
            rawPrice: '550,00',
            ndsPrice: '660,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '8.5',
            title:
              'ИЗМЕРЕНИЕ ЭКВИВАЛЕНТНОЙ РАВНОВЕСНОЙ ОБЪЁМНОЙ АКТИВНОСТИ (ЭРОА) РАДОНА-222 И ТОРОНА-220 В ВОЗДУХЕ ПОМЕЩЕНИЙ (1 точка)',
            type: 'Исследование',
            rawPrice: '380,00',
            ndsPrice: '456,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '8.6',
            title:
              'Удельная суммарная альфа-активность, суммарная бета-активность в воде (минерализованная и неминерализованная вода)',
            type: 'Исследование',
            rawPrice: '2012,5',
            ndsPrice: '2415,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '8.7',
            title: 'Определение объемной активности радона-222 в воде',
            type: 'Исследование',
            rawPrice: '491,67',
            ndsPrice: '590,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 9. БАКТЕРИОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1',
            title: 'ПИЩЕВЫЕ ПРОДУКТЫ',
            type: 'Исследование',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.1',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.2',
            title: 'L. monocytogenes ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.3',
            title: 'Бактерии рода Proteus',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.4',
            title: 'V. parahaemolyticus ',
            type: 'Исследование',
            rawPrice: '140,00',
            ndsPrice: '168,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.5',
            title: 'КМАФАнМ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.6',
            title: 'БГКП',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.7',
            title: 'Escherichia coli',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.8',
            title: 'Escherichia coli О157: Н7.',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.9',
            title: 'Bacillus cereus',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.10',
            title: 'Патогенные микроорганизмы, в т.ч. Сальмонеллы',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.11',
            title: 'Дрожжи, плесени',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.12',
            title: 'Сульфитредуцирующие клостридии',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.1.13',
            title: 'Бактерии рода Enterococus',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2',
            title: 'ПРОМЫШЛЕННАЯ СТЕРИЛЬНОСТЬ (Консервы группы А, Б, В, Г):',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.1',
            title: 'Спорообразующие мезофильные аэробные и факультативно-анаэробные микроорганизмы',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.2',
            title: 'Неспорообразующие микроорганизмы',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.3',
            title: 'Дрожжи, плесени',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.4',
            title: 'Молочнокислые микроорганизмы',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.5',
            title:
              'Неспорообразующие микроорганизмы, в т.ч. молочнокислые микроорганизмы, дрожжи и плесени (3 исследования)',
            type: 'Исследование',
            rawPrice: '385,00',
            ndsPrice: '462,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.6',
            title: 'Неспорообразующие микроорганизмы, в т.ч. дрожжи и плесени (2 исследования)',
            type: 'Исследование',
            rawPrice: '322,50',
            ndsPrice: '387,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.7',
            title: 'Мезофильные анаэробные клостридии (кроме клостридий ботулизма и перфрингенс)',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.8',
            title: 'Клостридии ботулинум и клостридии перфрингенс.',
            type: 'Исследование',
            rawPrice: '166,67',
            ndsPrice: '200,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.9',
            title: 'Спорообразующие термофильные аэробные и факультативно-анаэробные микроорганизмы',
            type: 'Исследование',
            rawPrice: '166,67',
            ndsPrice: '200,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.2.10',
            title: 'Спорообразующие термофильные анаэробные микроорганизмы',
            type: 'Исследование',
            rawPrice: '392,50',
            ndsPrice: '471,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.3',
            title: 'ПРОМЫШЛЕННАЯ СТЕРИЛЬНОСТЬ (группы Д, Е):',
            type: 'Исследование',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.3.1',
            title: 'КМАФАнМ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.3.2',
            title: 'БГКП',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.3.3',
            title: 'Сульфитредуцирующие клостридии',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.3.4',
            title: 'Bacillus cereus',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.3.5',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.3.6',
            title: 'Патогенные микроорганизмы, в т.ч. Сальмонеллы',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.3.7',
            title: 'Дрожжи, плесени',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.3.8',
            title: 'Молочнокислые микроорганизмы',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.4',
            title: 'ПРОМЫШЛЕННАЯ СТЕРИЛЬНОСТЬ ПРОДУКТОВ НА МОЛОЧНОЙ ОСНОВЕ АСЕПТИЧЕСКОГО РОЗЛИВА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.4.1',
            title: 'КМАФАнМ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.4.2',
            title: 'Микроскопия',
            type: 'Исследование',
            rawPrice: '91,67',
            ndsPrice: '110,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5',
            title: 'ВОДА, РАСФАСОВАННАЯ В ЁМКОСТИ, МИНЕРАЛЬНАЯ, НАПИТКИ БЕЗАЛКОГОЛЬНЫЕ, ПИВО, СЛАБОАЛКОГОЛЬНЫЕ НАПИТКИ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.1',
            title: 'ОМЧ при 22° С',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.2',
            title: 'ОМЧ при 37° С',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.3',
            title: 'КМАФАнМ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.4',
            title: 'Pseudomonas aeruginosa',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.5',
            title: 'Патогенные микроорганизмы, в т.ч. сальмонеллы',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.6',
            title: 'ОКБ, ТКБ, ГКБ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.7',
            title: 'БГКП (колиформы, фекальные)',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.8',
            title: 'Escherichia coli',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.9',
            title: 'Энтерококки',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.10',
            title: 'Коли-фаги (с накоплением)',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.11',
            title: 'Сульфитредуцирующие клостридии',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.5.12',
            title: 'Дрожжи, плесени',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.6',
            title: 'БУМАГА И КАРТОН, ПРЕДНАЗНАЧЕННЫЕ ДЛЯ УПАКОВКИ ПИЩЕВЫХ ПРОДУКТОВ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.6.1',
            title: 'ОМЧ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.6.2',
            title: 'БГКП',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.6.3',
            title: 'Патогенные микроорганизмы, в т.ч. сальмонеллы',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.7',
            title:
              'ПАРФЮМЕРНО-КОСМЕТИЧЕСКИЕ ИЗДЕЛИЯ, БЫТОВАЯ ХИМИЯ, САНИТАРНО-ГИГИЕНИЧЕСКИЕ ИЗДЕЛИЯ РАЗОВОГО ПОЛЬЗОВАНИЯ, В ТОМ ЧИСЛЕ ПРЕДНАЗНАЧЕННЫЕ ДЛЯ ДЕТЕЙ, ИГРУШКИ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.7.1',
            title: 'КМАФАнМ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.7.2',
            title: 'Бактерии семейства Enterobacteriaceae',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.7.3',
            title: 'Escherichia coli',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.7.4',
            title: 'Дрожжи, плесени',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.7.5',
            title: 'Candida albicans',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.7.6',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.7.7',
            title: 'Pseudomonas aeruginosa',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.7.8',
            title: 'ДИСТИЛЛИРОВАННАЯ ВОДА, ВОДА ДЛЯ ГЕМОДИАЛИЗА',
            type: 'Cтерильность',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.8',
            title: 'ДИСТИЛЛИРОВАННАЯ ВОДА, ВОДА ДЛЯ ГЕМОДИАЛИЗА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.8.1',
            title: 'ОМЧ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.8.2',
            title: 'Коли-индекс, БГКП',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.8.3',
            title: 'Плесневые грибы',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.8.4',
            title: 'Патогенные энтеробактерии, в т.ч. сальмонеллы',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.8.5',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.8.6',
            title: 'Pseudomonas aeruginosa',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.8.7',
            title: 'Общее количество непатогенных микроорганизмов (ОМЧ, плесневых грибов, и дрожжей)',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.9',
            title: 'ВОДА ПИТЬЕВАЯ ЦЕНТРАЛИЗОВАННОГО И НЕЦЕНТРАЛИЗОВАННОГО ВОДОСНАБЖЕНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.9.1',
            title: 'ОМЧ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.9.2',
            title: 'ОКБ (общие (обобщенные) колиформные бактерии',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.9.3',
            title: 'Патогенные микроорганизмы, в т.ч. сальмонеллы',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.9.4',
            title: 'Колифаги (с накоплением)',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.9.5',
            title: 'Споры сульфитредуцирующих клостридий',
            type: 'Исследование',
            rawPrice: '140,00',
            ndsPrice: '168,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.9.6',
            title: 'Энтерококки',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.9.7',
            title: 'Escherichia coli',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.9.8',
            title:
              'Основные санитарно-микробиологические показатели безопасности воды, (ОМЧ, ОКБ, колифаги, энтерококки, Escherichia coli)',
            type: 'Исследование',
            rawPrice: '1 208,33',
            ndsPrice: '1 450,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.10',
            title: 'ВОДА КУПАЛЬНО-ПЛАВАТЕЛЬНЫХ БАССЕЙНОВ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.10.1',
            title: 'ОМЧ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.10.2',
            title: 'ОКБ (общие (обобщенные) колиформные бактерии',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.10.3',
            title: 'Колифаги (с накоплением)',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.10.4',
            title: 'Патогенные микроорганизмы, в т.ч. сальмонеллы',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.10.5',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.10.6',
            title: 'Pseudomonas aeruginosa',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.10.7',
            title: 'Энтерококки',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.10.8',
            title: 'Escherichia coli',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11',
            title: 'ВОДА ОТКРЫТЫХ ВОДОЁМОВ, СТОЧНАЯ ВОДА, ТЕХНИЧЕСКАЯ ВОДА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11.1',
            title: 'ОМЧ при 220 С',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11.2',
            title: 'ОМЧ при 370 С',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11.3',
            title: 'ОКБ (общие (обобщенные) колиформные бактерии)',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11.4',
            title: 'Колифаги (без накопления)',
            type: 'Исследование',
            rawPrice: '166,67',
            ndsPrice: '200,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11.5',
            title: 'Патогенные микроорганизмы, в т.ч. сальмонеллы',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11.6',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11.7',
            title: 'Энтерококки',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11.8',
            title: 'Escherichia coli',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.11.9',
            title: 'Споры сульфитредуцирующих клостридий',
            type: 'Исследование',
            rawPrice: '140,00',
            ndsPrice: '168,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.12',
            title: 'ПОЧВА, ПЕСОК, ИЛОВЫЕ И ДОННЫЕ ОТЛОЖЕНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.12.1',
            title: 'Энтерококки (фекальные стрептококки)',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.12.2',
            title: 'Патогенные энтеробактерии, в т.ч. сальмонеллы',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.12.3',
            title: 'ОКБ (общие (обобщенные) колиформные бактерии), E.coli, БГКП',
            type: 'Исследование',
            rawPrice: '385,00',
            ndsPrice: '462,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.12.4',
            title: 'Споры сульфитредуцирующих клостридий',
            type: 'Исследование',
            rawPrice: '140,00',
            ndsPrice: '168,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.13',
            title: 'ЛЕЧЕБНАЯ ГРЯЗЬ',
            type: 'Исследование',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.13.1',
            title: 'ОМЧ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.13.2',
            title: 'Лактозоположительные кишечные палочки (ЛКП)',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.13.3',
            title: 'Энтерококки',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.13.4',
            title: 'Сульфитредуцирующие клостридии',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.13.5',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '205,00',
            ndsPrice: '246,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.13.6',
            title: 'Pseudomonas aeruginosa',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.14',
            title: 'ВОЗДУХ (аспирационный метод). :',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.14.1',
            title: 'ОМЧ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.14.2',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '205,00',
            ndsPrice: '246,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.14.3',
            title: 'Дрожжи, плесени',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.15',
            title: 'СМЫВЫ С ОБЪЕКТОВ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.15.1',
            title: 'БГКП',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.15.2',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.15.3',
            title: 'Патогенная микрофлора, в т.ч. сальмонеллы',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.15.4',
            title: 'Условно-патогенная микрофлора, в т. ч. НГОБ',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.15.5',
            title: 'Pseudomonas aeruginosa',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.15.6',
            title: 'Плесени',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.15.7',
            title: 'ОМЧ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16',
            title: 'СМЫВЫ (ОБОРУДОВАНИЕ), СМЫВНАЯ ЖИДКОСТЬ, КОНТРОЛЬЭФФЕКТИВНОСТИ ОБРАБОТКИ РУК ПЕРСОНАЛА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.1',
            title: 'Иерсинии',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.2',
            title: 'Листерии',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.3',
            title: 'Бактериологический контроль эффективности обработки рук персонала',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.4',
            title: 'ОМЧ',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.5',
            title: 'БГКП',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.6',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '205,00',
            ndsPrice: '246,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.7',
            title: 'Pseudomonas aeruginosa',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.8',
            title: 'Плесени и дрожжи',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.9',
            title: 'Патогенная микрофлора, в т.ч. сальмонеллы',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.16.10',
            title: 'Условно-патогенная микрофлора, в т. ч. НГОБ',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.17',
            title: 'СТЕРИЛЬНОСТЬ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.17.1',
            title: 'Материал на стерильность',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.17.2',
            title: 'Компоненты (препараты) донорской крови',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.18',
            title: 'АПТЕЧНЫЕ ФОРМЫ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.18.1',
            title: 'КМАФАнМ (ОМЧ)',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.18.2',
            title: 'БГКП, Бактерии семейства Enterobacteriaceae',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.18.3',
            title: 'Staphylococcus aureus',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.18.4',
            title: 'Pseudomonas aeruginosa',
            type: 'Исследование',
            rawPrice: '180,00',
            ndsPrice: '216,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.18.5',
            title: 'Плесени',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.18.6',
            title: 'Сальмонеллы',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.18.7',
            title: 'Пирогенобразующие микроорганизмы',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.18.8',
            title: 'Стерильность',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.19',
            title: 'КОНТРОЛЬ РАБОТЫ СТЕРИЛИЗУЮЩЕЙ АППАРАТУРЫ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.19.1',
            title: 'Дезкамер с использованием индикаторов',
            type: 'Исследование',
            rawPrice: '772,50',
            ndsPrice: '927,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.19.2',
            title: 'Автоклавов с использованием индикаторов',
            type: 'Исследование',
            rawPrice: '1608,33 ',
            ndsPrice: '1930,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.19.3',
            title: 'Сухожаровых шкафов с использованием индикаторов биологических одноразовых',
            type: 'Исследование',
            rawPrice: '1 825,00',
            ndsPrice: '2 190,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.19.4',
            title: 'Устойчивость микроорганизмов к дезинфектантам',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.20',
            title: 'ЭФФЕКИВНОСТЬ ДЕЗИНФИЦИРУЮЩИХ СРЕДСТВ',
            type: 'Исследование',
            rawPrice: '192,50',
            ndsPrice: '231,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21',
            title: 'БАКТЕРИОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ КЛИНИЧЕСКОГО МАТЕРИАЛА',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.1',
            title: 'Микроскопия препарата',
            type: 'Исследование',
            rawPrice: '91,67',
            ndsPrice: '110,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.2.1',
            title: 'На возбудителей дифтерии (отделяемое зева)',
            type: 'Исследование',
            rawPrice: '230,00',
            ndsPrice: '276,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.2.2',
            title: 'На возбудителей дифтерии (отделяемое носа)',
            type: 'Исследование',
            rawPrice: '230,00',
            ndsPrice: '276,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.3',
            title: 'На стафилококк:',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.3.1',
            title: 'Отделяемое зева',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.3.2',
            title: 'Отделяемое носа',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.4',
            title: 'На возбудителей коклюша и паракоклюша',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.5.1',
            title: 'На стрептококки (отделяемое зева)',
            type: 'Исследование',
            rawPrice: '230,00',
            ndsPrice: '276,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.5.2',
            title: 'На стрептококки (отделяемое носа)',
            type: 'Исследование',
            rawPrice: '230,00',
            ndsPrice: '276,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.6',
            title: 'На менингококк, другие возбудители гнойных бактериальных менингитов:',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.6.1',
            title: 'Носоглоточная слизь',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.6.2',
            title: 'Ликвор, кровь',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.6.3',
            title: 'Микроскопия',
            type: 'Исследование',
            rawPrice: '91,67',
            ndsPrice: '110,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.6.4',
            title: 'Аутопсийны материал',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.7',
            title: 'На грибы рода Кандида',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.8',
            title: 'Кровь на стерильность',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.9',
            title: 'Кровь на гемокультуру',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.10',
            title: 'Мокрота, мазки из носа, зева на микрофлору',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.11',
            title: 'Моча, желчь, отделяемое глаз, ушей, ран, пунктатов, женских половых органов на микрофлору',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.12',
            title: 'Грудное молоко на микрофлору',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.13',
            title: 'Материала при аутопсии (секционный материал) на микрофлору',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.14',
            title: 'На патогенные энтеробактерии',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.15',
            title: 'На энтеропатогенные эшерихии',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.16',
            title: 'Испражнения на условно-патогенные энтеробактерии (количественный метод)',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.17',
            title: 'На кишечный дисбактериоз',
            type: 'Исследование',
            rawPrice: '451,67',
            ndsPrice: '542,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.18',
            title: 'На кампилобактерии',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.21.19',
            title: 'Испражнения на стафилококк',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.22',
            title: 'ОПРЕДЕЛЕНИЕ ЧУВСТВИТЕЛЬНОСТИ МИКРООРГАНИЗМОВ К АНТИБИОТИКАМ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.22.1',
            title: 'Определение чувствительности микрооганизмов методом бумажных дисков (6 антибиотиков) (за 1 микроорганизм)',
            type: 'Исследование',
            rawPrice: '255,83',
            ndsPrice: '307,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.22.2',
            title: 'Определение чувствительности микрооганизмов к бактериофагам (за один микроорганизм к одному бактериофагу)',
            type: 'Исследование',
            rawPrice: '179,17',
            ndsPrice: '215,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.22.3',
            title: 'Идентификация культур',
            type: 'Исследование',
            rawPrice: '348,33',
            ndsPrice: '418,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.23',
            title: 'СЕРОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ (СЫВОРОТКИ КРОВИ)',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.23.1',
            title: 'На носительство возбудителя брюшного тифа S.typhi (реакция Ви-гемагглютинации)',
            type: 'Исследование',
            rawPrice: '398,33',
            ndsPrice: '478,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.23.2',
            title: 'Развернутая пробирочная реакция агглютинации (РА) с одним диагностикумом',
            type: 'Исследование',
            rawPrice: '228,33',
            ndsPrice: '274,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.23.3',
            title: 'РПГА с одним эритроцитарным диагностикумом (микрометод)',
            type: 'Исследование',
            rawPrice: '589,17',
            ndsPrice: '707,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.23.4',
            title: 'РПГА с одним антигеном для определения напряженности иммунитета микрометодом',
            type: 'Исследование',
            rawPrice: '589,17',
            ndsPrice: '707,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '9.23.5',
            title: 'Определение антител к возбудителю дифтерии, столбняка, коклюша методом ИФА',
            type: 'Исследование',
            rawPrice: '911,67',
            ndsPrice: '1 094,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 10. ОСОБО-ОПАСНЫЕ ИНФЕКЦИИ',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '10.1',
            title: 'ОБЪЕКТЫ ВНЕШНЕЙ СРЕДЫ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.1.1',
            title:
              'Вода на легионеллёз (источники централизованного водоснабжения, воды купально-плавательных бассейнов (аквапарков) технической воды, горячего водоснабжения, системы кондиционирования с водным охлаждением (идентификация вида, количественное определение)',
            type: 'Исследование',
            rawPrice: '515,00',
            ndsPrice: '618,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.1.2',
            title: 'Смывы на легионеллёз (идентификация вида, качественное исследование) бактериологический метод',
            type: 'Исследование',
            rawPrice: '385,00',
            ndsPrice: '462,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.1.3',
            title: 'На холеру (бактериологический метод)',
            type: 'Исследование',
            rawPrice: '322,50',
            ndsPrice: '387,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.2',
            title:
              'ПРОВЕРКА ПЛОТНОЙ ПИТАТЕЛЬНОЙ СРЕДЫ ДЛЯ ДИАГНОСТИКИ ХОЛЕРЫ НА РОСТОВЫЕ КАЧЕСТВА (щелочной агар, TSBC-агар) 1 образец',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.3',
            title:
              'ПРОВЕРКА ПЛОТНОЙ ПИТАТЕЛЬНОЙ СРЕДЫ ДЛЯ ДИАГНОСТИКИ ХОЛЕРЫ НА РОСТОВЫЕ КАЧЕСТВА (основной пептон) 1 образец',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.4',
            title:
              'ПРОВЕРКА ПОЛИУГЛЕВОДНОЙ ПИТАТЕЛЬНОЙ СРЕДЫ ДЛЯ ДИАГНОСТИКИ ХОЛЕРЫ (лактозо-сахарозная среда, агар Клиглера, агар Ресселя) 1 образец',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.5',
            title:
              'КОНТРОЛЬ ПИТАТЕЛЬНЫХ СРЕД ДЛЯ ВЫДЕЛЕНИЯ, КУЛЬТИВИРОВАНИЯ И ИДЕНТИФИКАЦИИ ВОЗБУДИТЕЛЯ ХОЛЕРЫ (щелочной агар, TSBC-агар, основной пептон, полиуглеводные среды) 3 образца',
            type: 'Исследование',
            rawPrice: '385,00',
            ndsPrice: '462,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.6',
            title: 'БИОЛОГИЧЕСКИЙ МАТЕРИАЛ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.6.1',
            title: 'На холеру (бактериологический метод)',
            type: 'Исследование',
            rawPrice: '322,50',
            ndsPrice: '387,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.6.1.1',
            title: 'Выделение возбудителя холеры в рамках ОМС (бактериологический метод и метод ПЦР)',
            type: 'Исследование',
            rawPrice: '1070,00',
            ndsPrice: '1284,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '10.6.2',
            title: 'На кишечный иерсиниоз и псевдотуберкулёз (бактериологический метод)',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 11. САНИТАРНО-ПАРАЗИТОЛОГИЧЕСКИЕ, ПАРАЗИТОЛОГИЧЕСКИЕ, ЭНТОМОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1',
            title: 'САНИТАРНО-ПАРАЗИТОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.1',
            title:
              'Рыба, нерыбные объекты промысла и продукты, вырабатываемые из них, на живые личинки паразитов, опасных для здоровья человека ',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.2',
            title: 'Икры рыб на живые личинки паразитов, опасных для здоровья человека',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.3',
            title: 'Мясо и мясопродукты на зараженность личинками биогельминтов',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.4',
            title:
              'Плодоовощная, плодовоягодная и растительная продукции на яйца гельминтов и цисты патогенных кишечных простейших',
            type: 'Исследование',
            rawPrice: '221,67',
            ndsPrice: '266,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.5',
            title: 'Вода питьевая, расфасованная в ёмкости, на яйца гельминтов, цисты лямблий. Ооцисты криптоспоридий.',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.6',
            title: 'Вода питьевая централизованных систем питьевого водоснабжения на цисты лямблий',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.7',
            title: 'Вода плавательных бассейнов на яйца гельминтов и цисты патогенных кишечных простейших',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.8',
            title: 'Вода поверхностных водных объектов на яйца гельминтов и цисты патогенных кишечных простейших',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.9',
            title: 'Сточная вода на яйца гельминтов и цисты патогенных кишечных простейших',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.10',
            title: 'Донные отложения, органические удобрения на яйца гельминтов и цисты патогенных кишечных простейших',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.11',
            title: 'Почва, земля (в т.ч. с земельных участков) на наличие яиц и личинок гельминтов',
            type: 'Исследование',
            rawPrice: '194,17',
            ndsPrice: '233,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.12',
            title:
              'Почва, земля (в т.ч. с земельных участков) на наличие яиц гельминтов и цист патогенных простейших комплексным методом',
            type: 'Исследование',
            rawPrice: '205,00',
            ndsPrice: '246,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.13',
            title: 'Смывы с предметов окружающей среды на яйца гельминтов',
            type: 'Исследование',
            rawPrice: '155,00',
            ndsPrice: '186,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.1.14',
            title: 'Смывы с предметов окружающей среды на яйца гельминтов на цисты патогенных кишечных простейших',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2',
            title: 'ПАРАЗИТОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2.1',
            title:
              'Фекалии на гельминты методом визуального осмотра, промыванием и идентификацией паразитов и их фрагментов',
            type: 'Исследование',
            rawPrice: '103,33',
            ndsPrice: '124,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2.2',
            title: 'Фекалии на гельминтозы',
            type: 'Исследование',
            rawPrice: '118,33',
            ndsPrice: '142,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2.3',
            title: 'Фекалии на протозоозы',
            type: 'Исследование',
            rawPrice: '130,00',
            ndsPrice: '156,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2.4',
            title: 'Фекалии на криптоспородиоз',
            type: 'Исследование',
            rawPrice: '130,00',
            ndsPrice: '156,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2.5',
            title: 'Фекалии на личинки гельминтов',
            type: 'Исследование',
            rawPrice: '118,33',
            ndsPrice: '142,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2.6',
            title: 'Соскоб с перианальных складок на энтеробиоз',
            type: 'Исследование',
            rawPrice: '91,67',
            ndsPrice: '110,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2.7',
            title: 'Моча на яйца и личинки гельминтов',
            type: 'Исследование',
            rawPrice: '118,33',
            ndsPrice: '142,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2.8',
            title:
              'Дуоденальное содержимое на яйца, личинки и фрагменты гельминтов и цисты патогенных кишечных простейших',
            type: 'Исследование',
            rawPrice: '168,33',
            ndsPrice: '202,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.2.9',
            title: 'Кровь на малярию, филяриидозы и другие кровепаразиты',
            type: 'Исследование',
            rawPrice: '270,00',
            ndsPrice: '324,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.3',
            title: 'СЕРОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ (ИФА)',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.3.1',
            title: 'Токсокароз (ИФА)',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.3.2',
            title: 'Трихинеллез (ИФА)',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.3.3',
            title: 'Описторхоз (ИФА)',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.3.4',
            title: 'Эхинококкоз (ИФА)',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.3.5',
            title: 'Лямблиоз (ИФА)',
            type: 'Исследование',
            rawPrice: '374,17',
            ndsPrice: '449,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4',
            title: 'ЭНТОМОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.1',
            title: 'Бытовая пыль на наличие членистоногих (насекомые, клещи) (1 проба)',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.2',
            title: 'Биологический материал на наличие демодекоидных клещей с использованием липучей ленты (1 проба)',
            type: 'Исследование',
            rawPrice: '91,67',
            ndsPrice: '110,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.3',
            title: 'Кровососущие членистоногие на зараженность микрофиляриями (вскрытие 1 особи)',
            type: 'Исследование',
            rawPrice: '91,67',
            ndsPrice: '110,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.4',
            title: 'Партия товара на наличие членистоногих (вредителей запаса) визуальным методом',
            type: 'Исследование',
            rawPrice: '322,50',
            ndsPrice: '387,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.5',
            title:
              'Видовая диагностика клешей, комаров и др. членистоногих, имеющих медицинское значение (1 экземпляр) ',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.6',
            title:
              'Пищевые продукты (сухофрукты, орехи, зернобобовые, мука, крупы) на зараженность и загрязненность вредителями хлебных запасов (насекомые, клещи) (1 проба)',
            type: 'Исследование',
            rawPrice: '245,00',
            ndsPrice: '294,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.7',
            title: 'Пух, пер, мех, шерсть и других натуральные материалы на наличие насекомых и клещей (1 проба)',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.8',
            title: 'Почва, песок на наличие личинок и куколок синантропных мух',
            type: 'Исследование',
            rawPrice: '142,50',
            ndsPrice: '171,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.9',
            title:
              'Обследование жилых и общественных зданий и сооружений на наличие членистоногих (насекомые, клещи) (1 проба)',
            type: 'Исследование',
            rawPrice: '296,67',
            ndsPrice: '356,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.10',
            title:
              'Обследование подвальных помещений и открытых водоёмов на наличие личинок и куколок кровососущих комаров, оценка эффективности проведенных ларвицидных мероприятий:',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.10.1',
            title: 'Объект 1 категории - площадь объекта до 1,0 га',
            type: 'Исследование',
            rawPrice: '1 157,50',
            ndsPrice: '1 389,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.10.2',
            title: 'Объект 2 категории - площадь объекта от 1,1 га до 5,0 га',
            type: 'Исследование',
            rawPrice: '1 735,00',
            ndsPrice: '2 082,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.10.3',
            title: 'Объект 3 категории -площадь объекта свыше 5,1 га',
            type: 'Исследование',
            rawPrice: '2 311,67',
            ndsPrice: '2 774,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.11',
            title:
              'Обследование территорий парков и других объектов на наличие иксодовых клещей, оценка эффективности проведенных акарицидных мероприятий:',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.11.1',
            title: 'Объект 1 категории - площадь объекта до 1,0 га',
            type: 'Исследование',
            rawPrice: '1 157,50',
            ndsPrice: '1 389,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.11.2',
            title: 'Объект 2 категории - площадь объекта от 1,1 га до 5,0 га',
            type: 'Исследование',
            rawPrice: '1 735,00',
            ndsPrice: '2 082,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '11.4.11.3',
            title: 'Объект 3 категории -площадь объекта свыше 5,1 га',
            type: 'Исследование',
            rawPrice: '2 311,67',
            ndsPrice: '2 774,00',
          },
        ],
      },
      {
        id: uuidv4(),
        searchId: '',
        summary: 'Раздел 12. МОЛЕКУЛЯРНО-ГЕНЕТИЧЕСКИЕ ИССЛЕДОВАНИЯ',
        data: [
          {
            id: uuidv4(),
            searchId: '',
            number: '12.1',
            title: 'ИССЛЕДОВАНИЯ БИОЛОГИЧЕСКОГО МАТЕРИАЛА НА ВИРУСОЛОГИЧЕСКИЕ ПОКАЗАТЕЛИ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.1.1',
            title:
              'ОРВИ-скрин в биологическом материале (RS-вирус, Метапневмовирус, Бокавирус, Аденовирус, вирусы Парагриппа I, II, III, IV типов, Риновирус, Коронавирус) Определение РНК/ДНК вируса методом ПЦР',
            type: '1 проба',
            rawPrice: '1469,17',
            ndsPrice: '1763',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.1.2',
            title: 'Вирус гриппа (influenzae) А и В Определение РНК вируса методом ПЦР',
            type: '1 проба',
            rawPrice: '1019,17',
            ndsPrice: '1223',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.1.3',
            title: 'Mycoplasma pneumonia /Chlamydophila pneumonia. Определение ДНК вируса методом ПЦР',
            type: '1 проба',
            rawPrice: '724,17',
            ndsPrice: '869',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.1.4',
            title: 'Энтеровирус определение РНК вируса методом ПЦР',
            type: '1 проба',
            rawPrice: '856,67',
            ndsPrice: '1028',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.1.5',
            title:
              'Возбудители кишечных инфекций (ротавирус, норовирус, астровирус), определение РНК вируса методом ПЦР',
            type: '1 проба',
            rawPrice: '1019,17',
            ndsPrice: '1223',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.1.6',
            title:
              'Выявление и дифференциация ДНК бактерий рода Шигелла (Shigella spp.) и энтероинвазивных E.coli (EIEC), Сальмонелла (Salmonella spp.) и термофильных кампилобактерий (Campylobacter spp.), аденовирусов группы F (Adenovirus F) и РНК ротавирусов группы А (Rotavirus A), норовирусов 2-го генотипа (Norovirus 2-й генотип), астровирусов (Astrovirus) и энтеровирусов человека методом ПЦР',
            type: '1 проба',
            rawPrice: '1524,17',
            ndsPrice: '1829',
          },
          {
            id: uuidv4(),
            searchId: 'tickPrice',
            number: '12.1.7',
            title:
              'Выявление РНК вируса клещевого энцефалита, возбудителя иксодовых клещевых боррелиозов,  возбудителей моноцитарного эрлихиоза человека, ДНК возбудителя гранулоцитарного анаплазмоза человека методом ПЦР',
            type: '1 проба',
            rawPrice: '894,17',
            ndsPrice: '1073',
          },
          {
            id: uuidv4(),
            searchId: 'tickPrice',
            number: '12.1.8',
            title: 'Диагностика заболеваний, вызванных группой клещевых пятнистых лихорадок методом ПЦР ',
            type: '1 проба',
            rawPrice: '906,67',
            ndsPrice: '1 088,00',
          },
          {
            id: uuidv4(),
            searchId: 'tickPrice',
            number: '12.1.9',
            title:
              'Выявление РНК вируса Крымской геморрагической лихорадки в клиническом материале и клещах методом ПЦР ',
            type: '1 проба',
            rawPrice: '811,67',
            ndsPrice: '974',
          },
          {
            id: uuidv4(),
            searchId: 'tickPrice',
            number: '12.1.10',
            title:
              'Выявление РНК вируса клещевого энцефалита, возбудителей иксодовых клещевых боррелиозов,  возбудителей моноцитарного эрлихиоза человека, ДНК возбудителя гранулоцитарного анаплазмоза человека, возбудителей группы клещевых пятнистых лихорадок  и Крымской гемморагической лихорадки методом ПЦР',
            type: '1 проба',
            rawPrice: '1 486,67',
            ndsPrice: '1 784,00',
          },
          {
            id: uuidv4(),
            searchId: 'tickPrice',
            number: '12.1.11',
            title: 'Выявление одного маркера возбудителей инфекционныз заболеваний методом ПЦР',
            type: '1 проба',
            rawPrice: '676,67',
            ndsPrice: '812,00',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.2',
            title:
              'ИССЛЕДОВАНИЯ БИОЛОГИЧЕСКОГО МАТЕРИАЛА НА ВИРУСОЛОГИЧЕСКИЕ ПОКАЗАТЕЛИ в рамках ОМС для юридических лиц (ЛПУ), от 25 проб:',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.2.1',
            title:
              'Выявление РНК вирусов гриппа (influenzae) A и В, ОРВИ (RS-вирус, Метапневмовирус, Бокавирус, Аденовирус, вирусы Парагриппа I, II, III, IV типов, Риновирус, Коронавирус), Covid 19 (SARS-Cov), (MERS-Cov) методом ПЦР',
            type: '1 проба',
            rawPrice: '2034,17',
            ndsPrice: '2441',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.2.2',
            title:
              'Выявление РНК вируса гриппа (influenzae) A и В, ОРВИ-скрин (RS-вирус, Метапневмовирус, Бокавирус, Аденовирус, вирусы Парагриппа I, II, III, IV типов, Риновирус, Коронавирус)  методом ПЦР',
            type: '1 проба',
            rawPrice: '1579,17',
            ndsPrice: '1895',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.2.3',
            title:
              'ОРВИ-скрин в биологическом материале (RS-вирус, Метапневмовирус, Бокавирус, Аденовирус, вирусы Парагриппа I, II, III, IV типов, Риновирус, Коронавирус) Определение РНК/ДНК вируса методом ПЦР',
            type: '1 проба',
            rawPrice: '1196,67',
            ndsPrice: '1436',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.2.4',
            title: 'Вирус гриппа (influenzae) А и В Определение РНК вируса методом ПЦР',
            type: '1 проба',
            rawPrice: '754,17',
            ndsPrice: '905',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.2.5',
            title: 'Covid 19 (SARS-Cov), (MERS-Cov), определение методом ПЦР',
            type: '1 проба',
            rawPrice: '599,17',
            ndsPrice: '719',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.3',
            title: 'САНИТАРНО-ВИРУСОЛОГИЧЕСКИЕ ИССЛЕДОВАНИЯ',
            type: '',
            rawPrice: '',
            ndsPrice: '',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.3.1',
            title:
              'Возбудители кишечных инфекций (норовирус, ротавирус, астровирус, энтеровирус, вирус гепатита А) в объектах окружающей среды (смывы) методом ПЦР',
            type: '1 проба',
            rawPrice: '1754,17',
            ndsPrice: '2105',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.3.2',
            title:
              'Возбудители кишечных инфекций (ротавирус, норовирус, астровирус, энтеровирус, гепатит А), определение РНК вируса методом ПЦР в 10 литрах',
            type: '1 проба',
            rawPrice: '2239,17',
            ndsPrice: '2687',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.3.3',
            title: 'Выявление вируса гепатита А в объектах окружающей среды методом ПЦР',
            type: '1 проба',
            rawPrice: '1004,17',
            ndsPrice: '1205',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.3.4',
            title: 'Выявление энтеровируса в объектах окружающей среды методом ПЦР',
            type: '1 проба',
            rawPrice: '1004,17',
            ndsPrice: '1205',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.3.5',
            title:
              'Выявление и дифференциация ДНК бактерий рода шигелла (Shigella spp.) и энтероинвазивных E.coli (EIEC), сальмонелла (Salmonella spp.) и термофильных кампилобактерий (Campylobacter spp.), аденовирусов группы F (Adenovirus F) и РНК ротавирусов группы А (Rotavirus A), норовирусов 2-го генотипа (Norovirus 2-й генотип), астровирусов (Astrovirus), энтеровируса и вируса гепатита А методом ПЦР в 10 литрах',
            type: '1 проба',
            rawPrice: '2305',
            ndsPrice: '2766',
          },
          {
            id: uuidv4(),
            searchId: '',
            number: '12.3.6',
            title: 'Выявление одного маркера возбудителей инфекционных заболеваний методом ПЦР',
            type: '1 проба',
            rawPrice: '1 016,67',
            ndsPrice: '1 220,00',
          },
        ],
      },
    ],
  }),
  rootGetters: {},
  mutations: {},
  actions: {},
};

export default PriceTable;
